/*globals JSGlobals*/

export const getPlayerId = (
  isContextualVideo = false,
  isArticleTypeVideo = false,
) => {
  const { article } = JSGlobals
  const isSponsoredArticle =
    article.sponsorName !== undefined && article.sponsorName !== ''

  switch (true) {
    case isContextualVideo:
      return 'xt5hw'
    case article.isPhotoArticle:
      return 'x8cdk'
    case isArticleTypeVideo:
      return 'xo1pp'
    case isSponsoredArticle:
      return 'xplqm'

    default:
      return article.videoPlayerId
  }
}
