import React from 'react'
import Camera from '~/library/Article/Camera'
import Video from '~/library/Article/Video'
import Audio from '~/library/Article/Audio'

const ArticleIcon = (isPhoto = false, isVideo = false, isAudio = false) => {
  if (isPhoto) {
    return <Camera />
  }
  if ((isVideo && !isAudio && !isPhoto) || (isVideo && !isAudio)) {
    return <Video />
  }
  if ((isAudio && isVideo) || isAudio) {
    return <Audio />
  }
}

export default ArticleIcon
