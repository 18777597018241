/*globals JSGlobals, tp */
import { isPianoSubscriber } from '~/js/piano'
import { axateSubscriber } from './axate'
import { jsLoader } from './fileLoaders'

const watchMeter = () =>
  new Promise((resolve) => {
    window.tp = window.tp || []
    if (JSGlobals.premium) {
      return resolve('contentHidden')
    }
    tp.push(['addHandler', 'meterExpired', () => resolve('contentHidden')])
    tp.push(['addHandler', 'meterActive', () => resolve('meterActive')])
    // fallback if no metering
    setTimeout(() => resolve('notMetered'), 6000)
  })

const checkPianoMeter = async () => {
  watchMeter().then((status) => {
    if (status === 'contentHidden') {
      // set outbrain fallback id
      document
        .querySelectorAll('.OUTBRAIN:not(.hidden):last-child')
        .forEach((el) => el.setAttribute('data-widget-id', 'AR_13'))
    }
    jsLoader(['//widgets.outbrain.com/outbrain.js'], 'outbrain')
  })
}

export const initOutbrain = () => {
  if (
    !JSGlobals.piano ||
    isPianoSubscriber() ||
    axateSubscriber() ||
    !['article', 'photo article'].includes(JSGlobals.pageType)
  ) {
    // article content not metered, load as normal
    jsLoader(['//widgets.outbrain.com/outbrain.js'], 'outbrain')
  } else {
    checkPianoMeter()
  }
}
