import { jsLoader } from './fileLoaders'
import { initAds } from '~/js/main'
import { getStringBeforeValue } from './get-string-before-value'
import { Domain } from '~/types'

export const initOptOutTag = (domain: Domain) => {
  // Remove the top level domain from the domain (ie. '.co.uk')
  const secondLevelDomainName = getStringBeforeValue(domain, '.')

  jsLoader(
    ['https://cdn.optoutadvertising.com/script/ootag.v2.min.js'],
    'optoutadvertising',
  )
    .then(() => {
      const ootag = window.ootag || {}
      ootag.queue = ootag.queue || []
      ootag.queue.push(() => {
        ootag.initializeOo({
          publisher: 126,
          onlyNoConsent: 1,
          consentTimeOutMS: 500,
          noRequestsOnPageLoad: 1,
        })
      })
      initAds(null, secondLevelDomainName)
    })
    .catch((error) => {
      console.error('Error loading Opt Out script:', error)
    })
}
