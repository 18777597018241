/*globals apstag, googletag */
import sha256 from 'crypto-js/sha256'
import { getCookie, setCookie } from '~/util/cookie'
import { isThirdPartyModeEnabled } from '~/util/speed-tool'

const APSTAG_PROJECT_ALIAS = 'apstag'
const { isEnabled, enabledValues } = isThirdPartyModeEnabled()
const isScriptEnabled = enabledValues.includes(APSTAG_PROJECT_ALIAS)

export const initApstagScript = () => {
  if (isEnabled && !isScriptEnabled) {
    return
  }

  !(function (e, a, n, t, i, s, c) {
    function o(n, t) {
      a[e]._Q.push([n, t])
    }

    a[e] ||
      (a[e] = {
        init: function () {
          o('i', arguments)
        },
        fetchBids: function () {
          o('f', arguments)
        },
        setDisplayBids: function () {},
        targetingKeys: function () {
          return []
        },
        _Q: [],
      })
    const script = n.createElement(t)
    ;(script.async = !0),
      (script.src = '//c.amazon-adsystem.com/aax2/apstag.js'),
      (c = n.getElementsByTagName(t)[0]).parentNode.insertBefore(script, c)
  })('apstag', window, document, 'script')
}

const initApstag = ([slots]) => {
  return new Promise((resolve) => {
    //APS throwing an exeption on end to end test, quick workaround, dont run this on localhost
    if (location.hostname === 'localhost' && location.port !== '8040') {
      return resolve()
    }

    // apstag.debug('enableConsole')
    apstag.init({
      pubID: '3147',
      adServer: 'googletag',
    })

    // Set user email hash
    const user = window.tp?.pianoId?.getUser()
    const cookie = getCookie('AMZN-Token')

    if (user && cookie === null) {
      const userEmail = user.email
      const hashedEmail = sha256(userEmail.toLowerCase()).toString()
      fetch('https://tk.amazon-adsystem.com/envelope/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        cache: 'no-cache',
        body: JSON.stringify({
          publisherId: '3147',
          hashedRecords: [
            {
              type: 'email',
              record: hashedEmail,
            },
          ],
          gdpr: 1,
          gdprConsent: window.JSGlobals?.consentString,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Network response was not ok.')
        })
        .then((response) => {
          setCookie(
            'AMZN-Token',
            response.AIPToken,
            response.cookieExpiry,
            true,
          )
        })
        .catch((error) => {
          console.log('Fetch failed: ', error.message)
        })
    }

    const apstagSlots = slots
      .filter((x) => !!x)
      .reduce((slots, slot) => {
        slots.push({
          slotID: slot.getSlotElementId(),
          slotName: slot.getTargeting('pos')[0],
          sizes: slot
            .getSizes()
            .map((size) =>
              size.length === 1 ? Object.values(size)[0] : Object.values(size),
            ),
        })
        return slots
      }, [])

    apstag.fetchBids(
      {
        slots: apstagSlots,
        timeout: 2e3,
      },
      function () {
        googletag.cmd.push(function () {
          apstag.setDisplayBids()
          resolve()
        })
      },
    )
  })
}

export default initApstag
