import curry from 'ramda/src/curry'

export const ADCONFIG_DESKTOP = 'desktop'
export const ADCONFIG_DESKTOP_LITE = 'desktopAdlite'
export const ADCONFIG_MOBILE = 'mobile'
export const ADCONFIG_MOBILE_LITE = 'mobileAdlite'
export const ADCONFIG_AMP = 'amp'
export const ADCONFIG_AMP_LITE = 'ampLite'

export const ADSLOT_TOPBANNER = 'top_banner'
export const ADSLOT_WALLPAPER = 'wallpaper'
export const ADSLOT_SEEDTAG = 'seedtag'
export const ADSLOT_NATIVE = 'native'
export const ADSLOT_NATIVE_SECTION = 'native_section'
export const ADSLOT_BOTTOM = 'bottom_banner'
export const ADSLOT_TEADS_1 = 'teads_adslot_1'
export const ADSLOT_TEADS_2 = 'teads_adslot_2'
export const ADSLOT_SIDEBAR = 'sidebar'
export const ADSLOT_VIDEO = 'video'
export const ADSLOT_3X3 = 'ad_slot'
export const ADSLOT_ROW_BILLBOARD = 'ad_row_billboard'
export const ADSLOT_ON_SCROLL = 'on_scroll'
export const ADSLOT_OUTBRAIN_MID = 'outbrain_mid'
export const ADSLOT_OUTBRAIN_FEED = 'outbrain_feed'
export const ADSLOT_OUTBRAIN_SIDEBAR = 'outbrain_sidebar'

export const ADSLOT_PHOTO_TOP = 'photo_top'
export const ADSLOT_PHOTO_BOTTOM = 'photo_bottom'
export const ADSLOT_PHOTO_SIDEBAR = 'photo_sidebar'
export const ADSLOT_PHOTO_MPU1 = 'photo_mpu1'
export const ADSLOT_PHOTO_MPU2 = 'photo_mpu2'
export const ADSLOT_PHOTO_MOBILE = 'mobile_photo_mpu1'

export const ADSETTING_PARAGRAPH_FREQ = 'paragraph_freq'
export const ADSETTING_PARAGRAPH_SIZE = 'paragraph_size'
export const ADSETTING_MPU1_POSITION = 'mpu1_pos'
export const ADSETTING_LIVEBLOG_FREQ = 'liveblog_post_freq'
export const ADSETTING_LIVEBLOG_SIZE = 'liveblog_post_size'

export const DAILYMOTION_TYPE = 'dailymotion'

export const DEFAULT_POSITIONS = {
  FIRST_TEADS: 4,
  SECOND_TEADS: 7,
  LONG_ARTICLE_EXTENDED: 7,
  LONG_ARTICLE_OTHER: 5,
  SHORT_ARTICLE: 3,
}

// @todo - leaving this here as it will soon be deprecated
/**
 * Gets the amp ad param object from the config data
 * @type {*}
 */
export const getAMPAdParams = curry(
  (adTargeting, configuration, article, slot) => ({
    slot,
    adTargeting,
    configuration,
    sections: article.sections[0].path.split('/'),
    rootSection: article.sections[0].name.toLowerCase().replace(/\s/g, '-'),
    ...article,
  }),
)
