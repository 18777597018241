import React from 'react'
import styled from 'styled-components'

const ArticleHeadline = styled(({ className, children }) => (
  <h2 className={className}>{children}</h2>
))`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`

export default ArticleHeadline
