import { getSlots } from './gpt'

export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'

const SINGLE_MPU_SIZE = {
  [SMALL]: [[300, 250]],
  [MEDIUM]: [[300, 250]],
  [LARGE]: [[300, 250]],
}

const DOUBLE_MPU_SIZE = {
  [SMALL]: [[300, 250]],
  [MEDIUM]: [[300, 600]],
  [LARGE]: [[300, 600]],
}

const SIDEBAR_DOUBLE_MPU = {
  [MEDIUM]: [
    [300, 250],
    [300, 600],
  ],
  [LARGE]: [
    [300, 250],
    [300, 600],
  ],
}

const {
  ARTICLEMPU1,
  ARTICLEMPU2,
  ARTICLEMPU3,
  ARTICLEMPU4,
  ARTICLEMPU5,
  GPT_BOTTOMBANNER,
  MPUD0,
  MPUD1,
  MPUS11,
  MPUS2,
  MPUS3,
  MPUS4,
  MPUS6,
  MPUS8,
  MPUS9,
  SIDEBARMPU1,
  SIDEBARMPU2,
  SIDEBARMPU3,
  SIDEBARMPU4,
  GPT_TEADSIB1,
  GPT_TEADSIB2,
  GPT_TOPBANNER,
} = getSlots()

export const prebidSizes = {
  [GPT_TOPBANNER]: {
    [SMALL]: [[320, 50]],
    [MEDIUM]: [[728, 90]],
    [LARGE]: [
      [970, 250],
      [728, 90],
    ],
  },
  [GPT_BOTTOMBANNER]: {
    [SMALL]: [[320, 50]],
  },
  [ARTICLEMPU1]: {
    [SMALL]: [[300, 250]],
    [MEDIUM]: [
      [300, 250],
      [300, 600],
    ],
    [LARGE]: [
      [300, 250],
      [300, 600],
    ],
  },
  [SIDEBARMPU1]: SIDEBAR_DOUBLE_MPU,
  [SIDEBARMPU2]: SIDEBAR_DOUBLE_MPU,
  [SIDEBARMPU3]: SIDEBAR_DOUBLE_MPU,
  [SIDEBARMPU4]: SIDEBAR_DOUBLE_MPU,
  [ARTICLEMPU2]: SINGLE_MPU_SIZE,
  [ARTICLEMPU3]: SINGLE_MPU_SIZE,
  [ARTICLEMPU4]: SINGLE_MPU_SIZE,
  [ARTICLEMPU5]: SINGLE_MPU_SIZE,
  [MPUS2]: SINGLE_MPU_SIZE,
  [MPUS3]: SINGLE_MPU_SIZE,
  [MPUS4]: SINGLE_MPU_SIZE,
  [MPUS6]: SINGLE_MPU_SIZE,
  [MPUS8]: SINGLE_MPU_SIZE,
  [MPUS9]: SINGLE_MPU_SIZE,
  [MPUS11]: SINGLE_MPU_SIZE,
  [MPUD0]: DOUBLE_MPU_SIZE,
  [MPUD1]: DOUBLE_MPU_SIZE,
  [GPT_TEADSIB1]: {
    [SMALL]: [[2, 1]],
    [MEDIUM]: [[2, 1]],
    [LARGE]: [[2, 1]],
  },
  [GPT_TEADSIB2]: {
    [SMALL]: [[2, 1]],
    [MEDIUM]: [[2, 1]],
    [LARGE]: [[2, 1]],
  },
}
