import * as Throttle from 'promise-parallel-throttle'
import { renderIslands } from '@brightsites/island-hydration'
import { HeroGrid } from '~/library/Article/HeroGrid'
import { smoothScroll } from '~/library/Article/smoothScroll'
import { ArticleAPI } from '~/types'
import { initAirGrid } from '~/util/airgrid'
import { initApstagScript } from '~/util/apstag'
import { archiveAccordion } from '~/util/archiveAccordion'
import articleNextPrev from '~/util/articleNextPrev'
import { initAudienceProject } from '~/util/audienceProject'
import { initCarbonTag } from '~/util/carbonTag'
import carousel from '~/util/carousel'
import clickTracking from '~/util/clickTracking'
import { initChartbeat } from '~/util/initChartbeat'
import { initDailyMotion } from '~/util/dailymotion'
import { updatePublishedDateAgo } from '~/util/date-ago'
// import commentCountWording from '~/util/commentCount'
import executeParallelAuctionProvider from '~/util/executeParallelAuctionProvider'
import { facebookSDK } from '~/util/facebookSDK'
import { jsLoader } from '~/util/fileLoaders'
import {
  fontawesomeScript,
  hubspotFormsScript,
  hubspotScript,
  initLiveRampScripts,
  newPolarNativeScript,
  newsletterPage,
  skimlinksScript,
} from '~/util/get-external-scripts'
import gpt from '~/util/gpt'
import { refreshImpressionViewable } from '~/util/gpt.util'
import hideWeatherWidgetOnScroll from '~/util/hideWeatherWidgetOnScroll'
import { trackArticleContentScroll } from '~/util/trackArticleContentScroll'
import { hubspotForm } from '~/util/hubspot-form'
import { imgFallback } from '~/util/imgFallback'
import { group, groupEnd } from '~/util/log'
import { initLotame } from '~/util/lotame'
import delayMenuHoverState from '~/util/menu'
import { initOneSignal } from '~/util/oneSignal'
import { optaScript } from '~/util/opta'
import { initOutbrain } from '~/util/outbrain'
import { initOptOutTag } from '~/util/ootag'
import { optOutTrialSites } from '~/config/JPIMConfig'
import { parselyProfileScript } from '~/util/parselyProfile'
import peopleBioMobile from '~/util/peopleBioMobile'
import { initialisePreConsent } from '~/util/pre-consent-scripts'
import accordion from '~/util/quizAccordion'
import { resizeStickySidebar } from '~/util/resize-sticky-sidebar'
import rubicon from '~/util/rubicon'
import { shareArticle } from '~/util/shareArticle'
import { socialShareModal } from '~/util/social-share-modal'
import { sourcePoint_v2_0 } from '~/util/sourcePoint'
import switchGeoLocation from '~/util/switchGeolocation'
import { toggleTabbedContent } from '~/util/toggle-tabbed-content'
import * as timer from '~/util/time-performance'
import { twitterWidget } from '~/util/twitterWidget'
import upNext from '~/util/upNext'
import { userStatus } from '~/util/userStatus'
import { initVideohub } from '~/util/videohub'
import { LiveblogHydrated } from './components/LiveblogHydrated/LiveblogHydrated'
import { BreakingNewsTicker } from './components/BreakingNewsTicker'
import InfiniteScroll from './components/InfiniteScroll'
import { TodaysDate } from './components/TodaysDate'
import { Twitter } from './components/Twitter'
import { WeatherWidget } from './components/WeatherWidget'
import ThemeProvider from './providers/ThemeProvider'
import adPartnerCarousel from '~/util/adPartnerCarousel'
import { initPartytown } from './util/workers'
import disqusCommentCountWording from '~/util/disqusCommentCount'
import vfCommentCountWording from '~/util/vfCommentCount'
import { continueReading } from '~/util/continueReading'
import { toggleHeroCaption } from '~/util/toggleHeroCaption'

// Initialise pre-consent scripts
initialisePreConsent()

// initialise partytown for handling web workers
initPartytown()

window.JSGlobals = window.JSGlobals ?? {
  mode: 'development',
}

// Scroll to top when user navigates back to an infinite scroll article
if (
  window.JSGlobals &&
  window.JSGlobals.pageType === 'article' &&
  window.JSGlobals.isInfiniteScrollArticle
) {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
  } else {
    window.onbeforeunload = function () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

export const initAds = (
  article?: ArticleAPI | null,
  secondLevelDomainName?: string,
) =>
  new Promise(function (resolve) {
    const init = () => {
      gpt(article, secondLevelDomainName)
        .then(executeParallelAuctionProvider)
        .then(refreshImpressionViewable)
        .then(resolve)
    }

    if (typeof window.loadAudienceProjectPredictions === 'function') {
      window.loadAudienceProjectPredictions('sakjohnston', init, {
        consentString: window.JSGlobals && window.JSGlobals.consentString,
        gdprApplies: window.JSGlobals && window.JSGlobals.gdprApplies,
      })
    } else {
      setTimeout(init)
    }
  })

const isDisqusSite = window.JSGlobals.domain === 'scotsman.com'
const isHalifaxCourier = window.JSGlobals.domain === 'halifaxcourier.co.uk'
const isNewcastleWorld = window.JSGlobals.domain === 'newcastleworld.com'

// Define script load order here:
const initialisers = [
  // Load consent manager
  {
    consent: {
      method: async () => {
        try {
          await sourcePoint_v2_0() // SourcePoint CMP
        } catch (e) {
          console.error('Error with SourcePoint Consent manager')
        } finally {
          userStatus() // Piano + Axate + Google Extended Access
          if (isHalifaxCourier) {
            await initDailyMotion() // DailyMotion video player Halifax Courier
          }
        }
      },
      // Audience Data
      dependents: {
        initLotame, // Lotame audience data capture
        windowItagRubicon: () => {
          // Rubicon/Magnite header bidding
          window.itag = new rubicon()
          window.itag.init
        },
        initAirGrid, // Airgrid audience segmentation
        // Prebid
        adScript: {
          method: async () => {
            await jsLoader(
              [
                'https://micro.rubiconproject.com/prebid/dynamic/11022.js?group=jpi',
              ],
              'rubicon',
            )
          },
        },
        initApstagScript, // Amazon audience
      },
    },
  },
  {
    ...(!isHalifaxCourier &&
      !isNewcastleWorld && {
        initDailyMotion: async () => {
          await initDailyMotion() // DailyMotion video player
        },
      }),
    initVideohub: async () => {
      await initVideohub() // nationalworldtv video player
    },
    initAudienceProject, // Audience Project surveys
    initGPTorOptOut: () => {
      // GPT or Opt Out
      // Check for user consent value in local storage
      const consentKeyPrefix = '_sp_user_consent_'

      // Check SourcePoint consent data
      const getSpData = (): string | null => {
        const consentKey = Object.keys(localStorage).find((key) =>
          key.startsWith(consentKeyPrefix),
        )
        return consentKey ? localStorage.getItem(consentKey) : null
      }

      const parseGdprData = (spData: string | null) =>
        spData ? JSON.parse(spData) : null

      const hasConsent = (gdprData: any): boolean =>
        !gdprData?.gdpr?.consentStatus?.rejectedLI

      const spData = getSpData()
      const gdprData = parseGdprData(spData)
      const hasUserConsented = hasConsent(gdprData)

      // User has consented to third party cookies
      if (hasUserConsented) {
        initAds()
      } else if (
        // User has rejected consent but is on an Opt Out trial publication
        window.JSGlobals &&
        optOutTrialSites.includes(window.JSGlobals.domain)
      ) {
        initOptOutTag(window.JSGlobals.domain)
      }
    },
  },
  {
    initOutbrain, // Outbrain
  },
  {
    initCarbonTag, // Carbon @todo define
  },
  {
    skimlinksScript, // Skimlinks affiliate links
    newPolarNativeScript, // Polar script for sponsored pages
  },
  {
    optaScript, // Sports widgets
    initOneSignal, // OneSignal push notifications
    hubspotScript, // Hubspot tracking
    hubspotFormsScript, // Hubspot forms
    newsletterPage,
    fontawesomeScript, // Jobs page assets
    initLiveRampScripts, // LiveRamp audience data
  },
  {
    initChartbeat, // Chartbeat tracking
  },
  {
    adPartnerCarousel,
    continueReading,
    toggleHeroCaption,
    parselyProfileScript, // Parsely personalized article recommendation
    resizeStickySidebar,
    clickTracking,
    articleNextPrev,
    upNext,
    delayMenuHoverState,
    accordion,
    hideWeatherWidgetOnScroll,
    trackArticleContentScroll,
    peopleBioMobile,
    toggleTabbedContent,
    socialShareModal,
    // commentCountWording,
    ...(isDisqusSite
      ? { disqusCommentCountWording }
      : { vfCommentCountWording }),
    switchGeoLocation,
    carousel,
    updatePublishedDateAgo,
    imgFallback,
    archiveAccordion,
    handleIslands: () => {
      renderIslands(
        {
          BreakingNewsTicker,
          InfiniteScroll,
          LiveblogHydrated,
          TodaysDate,
          Twitter,
          WeatherWidget,
          HeroGrid,
        },
        [ThemeProvider],
      )
    },
    facebookSDK,
    twitterWidget,
    shareArticle,
    hubspotForm,
    smoothScroll,
    ...(isNewcastleWorld && {
      initDailyMotion: async () => {
        await initDailyMotion() // DailyMotion video player Newcastle World
      },
    }),
  },
]

// @todo define types for this to move file to TypeScript
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const initPromises = (inits) =>
  Object.keys(inits).map((key) => async () => {
    const initFunc = inits[key]
    if (!initFunc) {
      return
    }
    timer.start(key)
    try {
      if (initFunc.method) {
        await initFunc.method()
        timer.end(key)
        if (initFunc.dependents) {
          const groupName = `${key} deps`
          group(groupName)
          await Throttle.all(initPromises(initFunc.dependents))
          groupEnd()
        }
      } else {
        await initFunc()
        timer.end(key)
      }
    } catch (e) {
      console.warn(`Error caught in ${key}()`)
      console.error(e)
    }
  })

const initialiseModules = async () => {
  for (let i = 0; i < initialisers.length; i++) {
    const groupName = `group ${i + 1}`
    group(groupName)
    await Throttle.all(initPromises(initialisers[i]))
    groupEnd()
  }
}

initialiseModules()
