import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { device } from '~/config/theme/JPIMStyles'

const PremiumContent = styled(
  ({ className, adLiteEnabled, children, isAxateEnabled }) => (
    <div
      className={[
        className,
        'premium',
        adLiteEnabled || isAxateEnabled ? '' : 'no-entitlement',
      ].join(' ')}
    >
      {children}
    </div>
  ),
)`
  display: ${({ isPremiumArticleLayout }) => isPremiumArticleLayout && 'grid'};
  grid-template-columns: ${({ isPremiumArticleLayout }) =>
    isPremiumArticleLayout && 'repeat(4, minmax(0, 1fr))'};
  column-gap: 4px;

  &.no-entitlement {
    > *:not(:first-child):not(:nth-child(2)) {
      display: none;
    }
  }

  &.fade-content {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      background: linear-gradient(
        transparent,
        ${({ fadeColour }) => fadeColour || 'white'}
      );
    }
  }

  &
    > *:not(
      .markup,
      .OUTBRAIN,
      .outer-wrapper,
      .related-articles,
      .info-box,
      .block-quote
    ),
  .metroArticleImage {
    grid-column: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout && '1/span 4'};

    @media ${device.tablet} {
      grid-column: ${({ isPremiumArticleLayout }) =>
        isPremiumArticleLayout && 'span 11 / span 11'};
    }
  }

  &
    > *:is(
      .markup,
      .OUTBRAIN,
      .outer-wrapper,
      .related-articles,
      .info-box,
      .block-quote
    ) {
    grid-column: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout && '1/span 4'};

    @media ${device.tablet} {
      grid-column: ${({ isPremiumArticleLayout }) =>
        isPremiumArticleLayout && '3/span 7'};
    }
  }

  .metroArticleImage {
    display: flex;
    flex-direction: column;
    border-bottom: ${({ isPremiumArticleLayout, theme }) =>
      isPremiumArticleLayout && `1px solid ${theme.greyContrast}`};
  }

  @media ${device.tablet} {
    grid-template-columns: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout && 'repeat(11, minmax(0, 1fr))'};
  }
`

// Axate scripts when purchasing an article add
// display: inherit!important to the PremiumContent div.
// This messes up styling as display:grid no longer used.

// So add display:grid conditionally to the content-root-wrapper div
// so that it can later be inherited.
const ContentRootWrapper = styled.div`
  display: ${({ isPremiumArticleLayout }) => isPremiumArticleLayout && 'grid'};
`

const NonPremiumContent = styled.div``

const ContentRoot = ({ premium, children, component, ...rest }) => {
  const Root = premium ? PremiumContent : NonPremiumContent

  let content

  if (component) {
    content = styled(component)
  } else {
    content = <Root {...rest}>{children}</Root>
  }

  return (
    <ContentRootWrapper
      id="content-root-wrapper"
      isPremiumArticleLayout={premium}
      data-testid="article-content-root-wrapper"
    >
      {content}
    </ContentRootWrapper>
  )
}

ContentRoot.propTypes = {
  premium: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  component: PropTypes.element,
  isAxateEnabled: PropTypes.bool,
}

ContentRoot.defaultProps = {
  premium: false,
  component: null,
  isAxateEnabled: false,
}

export default ContentRoot
