import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { ArticleData, Configuration, Domain, FC, Hubspot } from '~/types'
import newsletterSuccessText from './NewsletterSuccessText'
import ReCaptchaAmp from './ReCaptchaAmp'
import { getNewsletterHeading } from './utils/get-newsletter-heading'

// Custom Newsletter Rule config based on 'Video' articleType
// to supersede URL based rules
const videoArticleNewsletterRule = {
  customText: 'Keep up with the latest new videos with the Shots! Newsletter.',
  list: 'all_shots_opt_in',
}

const Wrapper = styled.div<{
  isFarmweek?: boolean
  isHorseweek?: boolean
}>`
  display: flex;
  position: relative;
  border-color: ${({ theme, isFarmweek, isHorseweek }) =>
    isFarmweek ? 'none' : isHorseweek ? 'none' : theme.primaryColor};
  border-width: ${({ isFarmweek, isHorseweek }) =>
    isFarmweek ? '0' : isHorseweek ? '0' : '1px'};
  border-style: solid;
  background: ${({ theme, isFarmweek, isHorseweek }) =>
    isFarmweek ? '#ce073f' : isHorseweek ? '#49914c' : theme.lightContrast};
  box-sizing: border-box;
  margin: 0 auto 20px;

  &.hidden {
    display: none;
  }

  &.desktop-only {
    display: none;
  }

  @media ${device.mobileL} {
    &.mobile-only {
      display: none;
    }

    &.desktop-only {
      display: flex;
    }
  }
`

const InnerWrapper = styled.div<{
  isFarmweek?: boolean
  isHorseweek?: boolean
}>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.mobileL} {
    padding: 24px;
  }

  form {
    display: flex;
    flex-flow: column;
  }

  .newsletter-input-wrapper {
    display: flex;
    flex-direction: column;

    @media ${device.tabletS} {
      flex-direction: row;
    }
  }

  .newsletter-input-inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    .input-submit-wrapper {
      display: flex;
    }
  }

  .newsletter-email {
    flex-grow: 3;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-weight: bolder;
    margin: 0 0 12px 0;
    height: 38px;
    padding: 0 8px;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;

    @media ${device.tabletS} {
      padding: 0 15px;
      margin: 0 12px 0 0;
    }
  }

  .newsletter-submit {
    flex-grow: 1;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.6px;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.lightContrast};
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    width: 100%;
    flex-shrink: 1.8;
    padding: 0 4px;
    height: 38px;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media ${device.tabletS} {
      max-width: 160px;
    }
  }

  .disabled-submit {
    display: none;
  }

  form.amp-form-submit-success {
    .newsletter-submit {
      display: none;
    }

    .disabled-submit {
      display: none;
    }

    .newsletter-email {
      display: none;
    }

    .newsletter-message-footer {
      display: none;
    }

    .header-div {
      display: none;
    }
  }

  .newsletter-message {
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    line-height: 17px;
  }

  .newsletter-message-footer {
    padding-top: 8px;
    width: 100%;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    line-height: 17px;
    font-size: 11px;
    color: ${({ theme, isFarmweek, isHorseweek }) =>
      isFarmweek || isHorseweek ? theme.lightContrast : theme.darkContrast};
  }

  .newsletter-message-footer a {
    color: ${({ theme, isFarmweek, isHorseweek }) =>
      isFarmweek || isHorseweek ? theme.lightContrast : theme.darkContrast};
  }

  .newsletter-submit {
    background-color: ${({ theme, isFarmweek, isHorseweek }) =>
      isFarmweek || isHorseweek ? theme.darkContrast : theme.primaryColor};
  }

  .header-div h2,
  .header-div span {
    color: ${({ theme, isFarmweek, isHorseweek }) =>
      isFarmweek || isHorseweek ? theme.lightContrast : theme.darkContrast};
  }
`

const NewsletterSuccessButton = styled.button`
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.6px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  background-color: ${({ theme }) => theme.primaryColor};
  -webkit-appearance: none;
  border: 0;
  margin: 0;
  padding: 0 10px;
  height: 38px;

  .button-text {
    text-decoration: none;
    color: ${({ theme }) => theme.lightContrast};
  }
`
const Header = styled.h2`
  margin: 0 0 16px;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.6px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.darkContrast};
  display: block;
  @media ${device.mobileL} {
    margin: 0 0 24px;
  }

  span {
    color: ${({ theme }) => theme.primaryColor};
    @media ${device.mobileL} {
      display: inline;
    }
  }

  .sidebar-newsletter & {
    width: 100%;
    margin: 22px 0 10px;
  }
`

export type NewsletterSignupProps = {
  configuration: Configuration
  data: ArticleData
  desktopOnly?: boolean
  domain: Domain
  formName: string
  isVideo?: boolean
  mobileOnly?: boolean
  pathName: string
  type?: string
}

const NewsletterSignup = styled<FC<NewsletterSignupProps>>(
  ({
    configuration,
    data = {},
    desktopOnly,
    domain = '',
    formName,
    isVideo = false,
    mobileOnly,
    pathName,
    type,
  }) => {
    const newsletterConfig: Hubspot | undefined =
      configuration && configuration.hubspot
    const piano = configuration.piano
    const { isSubscriber } = configuration
    const hasSubscription = piano?.subscription
    const isAdLite = piano?.adLite
    const isPremium = piano?.premium
    const isNewsletter =
      !isSubscriber && !hasSubscription && !isAdLite && !isPremium

    const firstMatchedSection = () => {
      const sectionPaths = ['/farm-week', '/horse-week', '/news/equestrian']
      const matchedSection = data?.sections?.find((section) =>
        sectionPaths.includes(section.path),
      )

      return matchedSection?.path || data?.section?.path || data?.path
    }

    let path = firstMatchedSection()

    const isFarmweek =
      data?.layout?.section?.path === '/farm-week' || path === '/farm-week'

    const isHorseweek =
      data?.layout?.section?.path === '/horse-week' ||
      path === '/horse-week' ||
      path === '/news/equestrian'

    if (formName === 'menu' && !path) {
      path = pathName
    }

    if (!newsletterConfig || !path) {
      return null
    }

    type FormData = {
      title?: string
      page: string
      portalId: string
      section?: string
      list: string
      newsletterName: string
      guid: string
      customText?: string
      newsletterTagline?: string
    }

    let formData: FormData = {
      ...newsletterConfig.default,
      title: configuration.name,
      page: data.type || 'NL Page',
      portalId: newsletterConfig.portalId,
      section: data.section
        ? data.section.name
        : data.sections
        ? data.sections.slice(-1)[0]?.name
        : 'Newsletter',
    }

    newsletterConfig.customSections &&
      Object.entries(newsletterConfig.customSections).forEach(
        ([key, value]) => {
          if (path?.includes(key)) {
            formData = {
              ...formData,
              ...value,
            }
          }
        },
      )

    const attrForm: any = {}
    if (formName) {
      attrForm[`data-vars-newsletter-form-${formName}`] = true
    } else {
      attrForm['data-vars-newsletter-form'] = true
    }

    const successButton = () => {
      if (isSubscriber || isNewsletter) {
        return (
          <NewsletterSuccessButton>
            <a className="button-text" href="/profile">
              Edit Preferences
            </a>
          </NewsletterSuccessButton>
        )
      }
      if (!isSubscriber) {
        return (
          <NewsletterSuccessButton>
            <a className="button-text" href="/subscriptions">
              Learn More
            </a>
          </NewsletterSuccessButton>
        )
      }
    }

    const wrapperId = formName
      ? `${formName}-newsletter-signup`
      : 'article-newsletter-signup'
    const formId = formName
      ? `${formName}-newsletter-signup-form`
      : 'newsletter-signup-form'

    return (
      <Wrapper
        id={wrapperId}
        data-testid="article-newsletter-su"
        aria-hidden="true"
        className={[
          mobileOnly ? 'mobile-only' : '',
          desktopOnly ? 'desktop-only' : ' ',
        ].join(' ')}
        isFarmweek={isFarmweek}
        isHorseweek={isHorseweek}
      >
        <InnerWrapper isFarmweek={isFarmweek} isHorseweek={isHorseweek}>
          <form
            id={formId}
            className="newsletter-signup-form"
            data-testid="article-newsletter-form"
            method="POST"
            action-xhr="/internal-api/newsletter"
            target="_top"
            {...attrForm}
            on={`submit:${formId}.submit`}
          >
            <div className="header-div">
              <Header data-testid="newsletter-heading">
                {getNewsletterHeading(
                  isVideo,
                  videoArticleNewsletterRule,
                  data,
                  formData,
                  domain,
                )}
              </Header>
            </div>
            <div className="newsletter-input-wrapper">
              <div className="newsletter-input-inner">
                <div className="input-submit-wrapper">
                  <input
                    name="email"
                    type="email"
                    aria-label="Email"
                    className="newsletter-email"
                    data-testid="newsletter-email-input"
                    placeholder="Enter your email"
                    required
                  />
                  <input name="prod_hcheck" type="text" hidden={true} />
                  <input
                    name="opt_in"
                    type="hidden"
                    readOnly={true}
                    value={
                      isVideo ? videoArticleNewsletterRule.list : formData.list
                    }
                  />
                  <input
                    name="source"
                    type="hidden"
                    readOnly={true}
                    value={`${formData.title} in ${formData.page}`}
                  />
                  <input
                    name="source_url"
                    type="hidden"
                    readOnly={true}
                    value={configuration.baseUrl + path}
                  />
                  <input
                    name="source_website_category"
                    type="hidden"
                    readOnly={true}
                    value={formData.section?.replace("'", '')}
                  />
                  <input
                    name="site_id"
                    type="hidden"
                    readOnly={true}
                    value={formData.portalId}
                  />
                  <input
                    name="form_id"
                    type="hidden"
                    readOnly={true}
                    value={formData.guid}
                  />
                  <input
                    type="submit"
                    value="Sign up"
                    data-testid="newsletter-signup-btn"
                    className="newsletter-submit"
                    name="submit-button"
                    disabled
                  />
                  <div className="newsletter-submit disabled-submit">
                    Sign up
                  </div>
                </div>
                {type === 'NewsLetterSignup' ? (
                  <ReCaptchaAmp siteKey={configuration.recaptchaSiteKey} />
                ) : null}
                <div
                  className="newsletter-message"
                  data-testid="newsletter-signup-success"
                  submit-success="true"
                >
                  <div>
                    <template type="amp-mustache">
                      <div>
                        <h3>Thank you for signing up!</h3>
                        <p>{newsletterSuccessText(configuration)}</p>
                      </div>
                      <div>{successButton()}</div>
                    </template>
                  </div>
                </div>
                <div
                  className="newsletter-message"
                  data-testid="newsletter-signup-error"
                  submit-error="true"
                >
                  <template type="amp-mustache">
                    Sorry, there seem to be some issues. Please try again later.
                  </template>
                </div>
                <div
                  className="newsletter-message"
                  data-testid="newsletter-signup-progress"
                  data-submitting="true"
                >
                  <template type="amp-mustache">Submitting...</template>
                </div>
                {type === 'NewsLetterSignup' ? null : (
                  <ReCaptchaAmp siteKey={configuration.recaptchaSiteKey} />
                )}
              </div>
            </div>
          </form>
        </InnerWrapper>
      </Wrapper>
    )
  },
)``

export default NewsletterSignup
