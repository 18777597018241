import { IslandForRender } from '@brightsites/island-hydration/lib/components'
import React, { ComponentType } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import { ArticleData, Configuration, FC } from '~/types'

const Wrapper = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 0;
`

const ButtonsWrapper = styled.div`
  margin: 24px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.breakingNewsGrey};
`

const TabButton = styled.button<{
  article: ArticleData
  hierarchyName?: string
}>`
  padding: 0 24px 16px;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  font-size: 18px;

  &.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 4px;
    background-color: ${({ theme, hierarchyName, article }) => {
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article?.sections)
      }
      return getSectionPrimaryColor(theme, hierarchyName)
    }};
  }
`

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Tab = styled.div`
  display: none;

  &.active-tab {
    display: block;
    animation: ${fadeInAnimation} 0.3s ease-in-out;
  }
`

// Include all possible types that can be passed to tabComponents
type TabComponentType = ComponentType<{
  configuration?: Configuration
  isPremium?: boolean
  strippedLeadText?: string
  content?: JSX.Element[]
  article?: ArticleData
  watchThisId?: number
}>

type TabbedContentProps = {
  configuration: Configuration
  isPremium: boolean
  strippedLeadText: string
  content: JSX.Element[]
  article: ArticleData
  watchThisId: number
  tabLabels: string[]
  tabComponents: TabComponentType[]
  hydratedComponent?: string
  hierarchy?: { name: string }[]
}

export const TabbedContent: FC<TabbedContentProps> = ({
  isPremium,
  strippedLeadText,
  content,
  article,
  watchThisId,
  tabLabels,
  tabComponents,
  hydratedComponent,
  configuration,
  hierarchy,
}) => {
  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined

  return (
    <Wrapper id="tab-container">
      <ButtonsWrapper>
        {/* Build the tab buttons  */}
        {tabLabels.map((label, i) => (
          <TabButton
            key={i}
            className={`tab${i === 0 ? ' active' : ''}`}
            data-tab={`tab${i + 1}`}
            article={article}
            hierarchyName={hierarchyName}
          >
            {label}
          </TabButton>
        ))}
      </ButtonsWrapper>

      {/* Build the tabbed content  */}
      {tabComponents.map((Component, i) => {
        return (
          <Tab
            key={i}
            id={`tab${i + 1}`}
            className={`tab-content${i === 0 ? ' active-tab' : ''}`}
          >
            {/* Check if the current component should be hydrated:
            NB. We are using 'displayName' instead of 'Component.name' in case 
            Component.name is minified or altered during the build process causing
            a mismatch between the server- and client-side component names */}
            {hydratedComponent === Component.displayName ? (
              <IslandForRender
                key={`${Component?.displayName?.toLowerCase()}-${i}`}
                name={Component.displayName}
                props={{ configuration, watchThisId }}
              >
                <Component
                  configuration={configuration}
                  watchThisId={watchThisId}
                />
              </IslandForRender>
            ) : (
              // Otherise render component without hydration
              <Component
                isPremium={isPremium}
                strippedLeadText={strippedLeadText}
                content={content}
                article={article}
              />
            )}
          </Tab>
        )
      })}
    </Wrapper>
  )
}
