/*globals googletag*/

import once from 'ramda/src/once'
import {
  DESKTOP_ADS_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '~/config/theme/JPIMStyles'
import { LARGE, MEDIUM, prebidSizes, SMALL } from './adSizes'
import edktAudiences from './edktAudiences'
import { getCarbonSegment } from './carbonTag'
import { isInView } from './gpt.util'

const PREBID_TIMEOUT = 2000

const sizeMappings = Object.entries(prebidSizes).reduce(
  (accumulator, [id, sizes]) => {
    accumulator[id] = Object.entries(sizes)
      .map(([device, sizes]) => {
        switch (device) {
          case SMALL:
            return { minViewPort: [0, 0], sizes }
          case MEDIUM:
            return { minViewPort: [TABLET_BREAKPOINT, 0], sizes }
          case LARGE:
            return { minViewPort: [DESKTOP_ADS_BREAKPOINT, 0], sizes }
          default:
            console.error('Unknown size constant:  ' + device)
            return null
        }
      })
      .filter(Boolean)
    return accumulator
  },
  {},
)

export const demandManager = ([slots], skipAdRefresh) =>
  new Promise((resolve) => {
    const pbjs = window.pbjs || {}
    pbjs.que = pbjs.que || []

    const { refresh: gptRefresh } = googletag.pubads()

    const callAdServer = once((ads) => {
      if (!skipAdRefresh) {
        ads.forEach((slot) => {
          if (isInView(document.getElementById(slot.getSlotElementId()))) {
            gptRefresh([slot])
          }
        })
      }
      resolve()
    })

    // request pbjs bids when it loads
    pbjs.que.push(function () {
      const carbon_segment = getCarbonSegment()

      const ozoneSettings = {}
      const ozoneTargeting = {
        carbon_segment,
      }

      pbjs.rp.requestBids({
        callback: () => callAdServer(slots),
        gptSlotObjects: slots,
        sizeMappings,
        data: {
          airgrid: {
            perid: edktAudiences,
          },
          ozoneSettings,
          ozoneTargeting,
        },
      })
    })

    setTimeout(() => callAdServer(slots), PREBID_TIMEOUT)
  })
