import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  clear: both;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`

const TikTok = ({ data: { url } }) => {
  return (
    <Wrapper>
      <amp-tiktok
        width="325"
        height="580"
        data-align-center={true}
        layout="responsive"
        data-src={url}
      />
    </Wrapper>
  )
}

TikTok.propTypes = {
  data: PropTypes.any.isRequired,
}

export default TikTok
