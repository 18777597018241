export const poll = (fn: () => boolean, timeout: number, interval: number) => {
  const endTime = Number(new Date()) + (timeout || 2000)
  interval = interval || 100
  let timeoutId = 0

  const checkCondition = function (
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void,
  ) {
    // If the condition is met, we're done!
    const result = fn()
    if (result) {
      resolve(result)
    }
    // If the condition isn't met but the timeout hasn't elapsed, go again
    else if (Number(new Date()) < endTime) {
      timeoutId = Number(setTimeout(checkCondition, interval, resolve, reject))
    }
    // Didn't match and too much time, reject!
    else {
      reject(new Error('timed out for ' + fn))
    }
  }

  return {
    promise: new Promise(checkCondition),
    timeoutId,
  }
}
