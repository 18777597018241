/* globals JSGlobals */
import { getCookie } from './cookie'
import { addCarbonSegmentToGPT } from './carbonTag'
import { axateSub } from './axate'
import { isLogin } from '~/js/piano'
import { isGaa } from './google/helper'
import { getSiteSections } from './adsHelper'
import { getAdSlotIncrementAppendix } from '~/providers/AdManager/utils/getAdSlotIncrement'
import {
  DESKTOP_ADS_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  LAPTOP_MEDIUM_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '~/config/theme/JPIMStyles'
import {
  ADSLOT_PHOTO_BOTTOM,
  ADSLOT_PHOTO_MOBILE,
  ADSLOT_PHOTO_MPU1,
  ADSLOT_PHOTO_MPU2,
  ADSLOT_PHOTO_SIDEBAR,
  ADSLOT_PHOTO_TOP,
} from '~/constants/ads'
import { getAdSlot, ppid } from './gpt.util'
import {
  widerArticleSites,
  longPagePhotoArticleSites,
  premiumArticleLayoutSites,
} from '~/config/JPIMConfig'
import { getPageId } from './getAdData'

export const maxLaptopBreakPoint = LAPTOP_BREAKPOINT - 1
const INSKIN_BREAKPOINT = 1260

const ALLOWED_REFRESH_SIZE = [
  [970, 250],
  [728, 90],
  [300, 600],
  [300, 250],
  [320, 50],
]

export const getSlots = () => {
  const slotIdAppendix = getAdSlotIncrementAppendix()

  return {
    GPT_TOPBANNER: 'topBanner' + slotIdAppendix,
    GPT_WALLPAPER: 'adSlotWallpaper' + slotIdAppendix,
    GPT_SEEDTAG: 'adSlotSeedTag' + slotIdAppendix,
    GPT_NATIVE: 'nativeBanner' + slotIdAppendix,
    GPT_BOTTOMBANNER: 'bottomBanner' + slotIdAppendix,
    GPT_TEADSIB1: 'teads_adslot1' + slotIdAppendix,
    GPT_TEADSIB2: 'teads_adslot2' + slotIdAppendix,
    GPT_TOPBANNER_LITE: 'liteTopBanner' + slotIdAppendix,
    GPT_WALLPAPER_LITE: 'liteAdSlotWallpaper' + slotIdAppendix,
    GPT_SEEDTAG_LITE: 'liteAdSlotSeedTag' + slotIdAppendix,
    GPT_NATIVE_LITE: 'liteNativeBanner' + slotIdAppendix,
    GPT_BOTTOMBANNER_LITE: 'liteBottomBanner' + slotIdAppendix,
    GPT_TEADSIB_LITE: 'liteAdSlotTead' + slotIdAppendix,
    GPT_BILLBOARD: 'billboard' + slotIdAppendix,

    ARTICLEMPU1: 'articleMPU1' + slotIdAppendix,
    ARTICLEMPU2: 'articleMPU2' + slotIdAppendix,
    ARTICLEMPU3: 'articleMPU3' + slotIdAppendix,
    ARTICLEMPU4: 'articleMPU4' + slotIdAppendix,
    ARTICLEMPU5: 'articleMPU5' + slotIdAppendix,
    MPUD0: 'mpuD0' + slotIdAppendix,
    MPUD1: 'mpuD1' + slotIdAppendix,
    MPUS2: 'mpuS2' + slotIdAppendix,
    MPUS3: 'mpuS3' + slotIdAppendix,
    MPUS4: 'mpuS4' + slotIdAppendix,
    MPUS5: 'mpuS5' + slotIdAppendix,
    MPUS6: 'mpuS6' + slotIdAppendix,
    MPUS7: 'mpuS7' + slotIdAppendix,
    MPUS8: 'mpuS8' + slotIdAppendix,
    MPUS9: 'mpuS9' + slotIdAppendix,
    MPUS10: 'mpuS10' + slotIdAppendix,
    MPUS11: 'mpuS11' + slotIdAppendix,
    SIDEBARMPU1: 'sidebarMPU1' + slotIdAppendix,
    SIDEBARMPU2: 'sidebarMPU2' + slotIdAppendix,
    SIDEBARMPU3: 'sidebarMPU3' + slotIdAppendix,
    SIDEBARMPU4: 'sidebarMPU4' + slotIdAppendix,
  }
}

const MULTI_AD_SIZES_DESKTOP = [
  [970, 250],
  [728, 90],
  [300, 250],
]

export const valueTargettingConverter = (val) => {
  switch (typeof val) {
    case 'boolean':
      return val.toString()
    case 'undefined':
      return 'false'
    default:
      return val
  }
}

export const isTextArticle = (JSGlobals) => {
  if (
    JSGlobals &&
    JSGlobals.article &&
    JSGlobals.pageType !== 'photo article'
  ) {
    return true
  }
  return null
}

export const getCustomParams = (JSGlobals, options) =>
  Object.entries({
    test: JSGlobals && JSGlobals.adTargeting.toString(),
    gs_cat: JSGlobals.grapeshot || 'DEFAULT',
    sensitive: JSGlobals.sensitive,
    premium: JSGlobals.premium,
    siteSection:
      JSGlobals &&
      JSGlobals.article &&
      getSiteSections(JSGlobals.article.siteSections),
    topics: JSGlobals.article?.topics?.map((topic) => topic.name),
    infiniteScrollArticle: JSGlobals.isInfiniteScrollArticle,
    paragraphnum: isTextArticle(JSGlobals) && JSGlobals.paragraphCount, // need to remain lowercase for Google Analytics
    affiliateArticle: JSGlobals && JSGlobals.article?.isAffiliated,
    pageid: JSGlobals && JSGlobals.pageId,
    polarpreview: /polarpreview=preview/.test(document.location.search),
    video: JSGlobals && JSGlobals.article && JSGlobals.article.isVideoArticle,
    login: isLogin(),
    googleExtendedAccess: isGaa(),
    ...options,
  }).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: valueTargettingConverter(value),
    }
  }, {})

export const setCustomTargetParam = (JSGlobals, options) => {
  const googletag = window.googletag || {}
  Object.entries(getCustomParams(JSGlobals, options)).forEach(([key, value]) =>
    googletag.pubads().setTargeting(key, value),
  )
}

const gpt = (article, secondLevelDomainName) => {
  const {
    GPT_TOPBANNER,
    GPT_WALLPAPER,
    GPT_SEEDTAG,
    GPT_NATIVE,
    GPT_BOTTOMBANNER,
    GPT_TEADSIB1,
    GPT_TEADSIB2,
    GPT_TOPBANNER_LITE,
    GPT_WALLPAPER_LITE,
    GPT_SEEDTAG_LITE,
    GPT_NATIVE_LITE,
    GPT_BOTTOMBANNER_LITE,
    GPT_TEADSIB_LITE,
    GPT_BILLBOARD,
  } = getSlots()

  return new Promise((resolve) => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const isMobile = windowWidth < TABLET_BREAKPOINT

    let subscriber = getCookie('ev_su', 'false')
    let subscriberResource = '/'
    let isAdFree = false
    if (JSGlobals.piano && JSGlobals.piano.subscription) {
      subscriber = getCookie('subscriber', 'false')
      subscriberResource = getCookie('sub_resource', '')
      isAdFree = subscriberResource === 'premium'
    }
    if (JSGlobals.domain === 'yorkshirepost.co.uk' && !subscriber) {
      subscriber = getCookie(axateSub, 'false')
      if (subscriber) {
        // axate has no resource white list
        subscriberResource = '/'
      }
    }
    let isSubscriber = subscriber === 'true'

    const isLoggedIn = getCookie('lgd_n', '')
    if (isLoggedIn && !isSubscriber) {
      subscriberResource = 'registered'
    }

    var googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []

    const { pathname } = window.location
    const path = pathname.split('/').filter((item) => item)
    const articleType = JSGlobals.articleType
    const pageType = JSGlobals.pageType
    const slot = getAdSlot()

    let topLevelSection = 'home'
    if (pathname.length > 1 && path.length > 0) {
      if (pageType !== 'category') path.pop()
      topLevelSection = path[0]
    }

    let wallpaper
    let seedtag
    let liteSeedtag
    let liteWallpaper
    let teadsib1
    let teadsib2
    let liteTeadsib
    let native
    let liteNative

    const sidebarPriorityAllocation = [1, 2, 5, 3, 4]
    const sidebarMPU = []

    const slotIncrementSelector = (() => {
      const appendix = getAdSlotIncrementAppendix()

      if (!appendix) {
        return ''
      }

      return `[id*=${appendix}]`
    })()

    document
      .querySelectorAll(`[id^=sidebarMPU]${slotIncrementSelector}`)
      .forEach((el) => sidebarMPU.push(el.id))

    const onscroll$ = []
    document
      .querySelectorAll('[id^=sidebar-onscroll]')
      .forEach((el) => onscroll$.push(el.id))

    const liteSidebarMPU = []
    document
      .querySelectorAll(`[id^=liteSidebarMPU]${slotIncrementSelector}`)
      .forEach((el) => liteSidebarMPU.push(el.id))

    const articleMPU = []
    document
      .querySelectorAll(
        `[id^=articleMPU]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => articleMPU.push(el.id))

    const articleLiveblogMPU = []
    document
      .querySelectorAll(
        `[id^=blogMpu]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => articleLiveblogMPU.push(el.id))

    const liteArticleMPU = []
    document
      .querySelectorAll(
        `[id^=liteArticleMPU]${slotIncrementSelector}${
          isMobile ? '[id*=-mobile]' : ':not([id*=-mobile])'
        }`,
      )
      .forEach((el) => liteArticleMPU.push(el.id))

    const sectionMPU = []
    document
      .querySelectorAll(`[id^=mpu]${slotIncrementSelector}`)
      .forEach((el) => sectionMPU.push(el.id))

    const leaderboard$ = []
    document
      .querySelectorAll('[id^=leaderboard]')
      .forEach((el) => leaderboard$.push(el.id))

    const liteArticleAdSlot$ = []
    document
      .querySelectorAll('[id^=liteArticleAdSlot]')
      .forEach((el) => liteArticleAdSlot$.push(el.id))

    const adRowSection$ = []
    document
      .querySelectorAll('[id^=adRow]')
      .forEach((el) => adRowSection$.push(el.id))

    let mpus = []
    let mpuBlog = []
    let sectionMpus = []
    let leaderboards = []
    let liteArticleAdSlots = []
    let adRows = []
    let topBanner
    let liteTopBanner
    let bottomBanner
    let liteBottomBanner
    let onscroll = []
    let billboards = []
    let photoAds = []

    let topBannerSize
    let bottomBannerSize
    if (windowWidth >= DESKTOP_ADS_BREAKPOINT) {
      topBannerSize = [970, 250]
    } else if (windowWidth >= TABLET_BREAKPOINT) {
      topBannerSize = [728, 90]
    } else {
      topBannerSize = [320, 50]
    }

    if (windowWidth >= TABLET_BREAKPOINT) {
      bottomBannerSize = [728, 90]
    } else {
      bottomBannerSize = [320, 50]
    }

    const isLongPagePhotoArticleSite = longPagePhotoArticleSites.includes(
      JSGlobals.domain,
    )

    let leaderboardMultiMobileSize

    if (isLongPagePhotoArticleSite) {
      leaderboardMultiMobileSize =
        windowWidth > maxLaptopBreakPoint
          ? MULTI_AD_SIZES_DESKTOP
          : windowWidth > TABLET_BREAKPOINT
          ? [
              [728, 90],
              [300, 250],
            ]
          : [
              [300, 250],
              [320, 50],
            ]
    } else {
      leaderboardMultiMobileSize =
        windowWidth >= TABLET_BREAKPOINT ? MULTI_AD_SIZES_DESKTOP : [320, 50]
    }

    const leaderboardSingleMobileSize =
      windowWidth >= TABLET_BREAKPOINT ? MULTI_AD_SIZES_DESKTOP : [320, 50]

    // polar can only target one id, so we set it based on device size
    if (document.querySelector('.polar-placeholder')) {
      if (windowWidth >= TABLET_BREAKPOINT) {
        document
          .querySelectorAll('.polar-placeholder.polar-desktop')
          .forEach((el) => {
            el.id = 'sponsored-teaser'
          })
      } else {
        document
          .querySelectorAll('.polar-placeholder.polar-mobile')
          .forEach((el) => {
            el.id = 'sponsored-teaser'
          })
      }
    }

    const teadSibSize = [2, 1]
    const wallPaperSize = [2, 2]
    const seedTagSize = [2, 3]
    const nativeSize = [1, 2]
    const adSlot3x3Size = [3, 3]
    const onscrollSize = [4, 4]

    const isPremiumArticleLayout =
      JSGlobals.premium && premiumArticleLayoutSites.includes(JSGlobals.domain)

    const billboardSize =
      isPremiumArticleLayout && windowWidth > TABLET_BREAKPOINT
        ? [
            [970, 250],
            [728, 90],
            [300, 250],
          ]
        : windowWidth > TABLET_BREAKPOINT
        ? [
            [970, 250],
            [728, 90],
          ]
        : [300, 250]

    const allowRefresh = (adSizes) => {
      const sizes = Array.isArray(adSizes?.[0]) ? adSizes : [adSizes]
      // check size exists in ALLOWED_REFRESH_SIZE array
      return sizes.some((size) =>
        ALLOWED_REFRESH_SIZE.some((allowedSize) =>
          allowedSize.every((val, index) => val === size[index]),
        ),
      )
    }

    const mpuSize = (i, isSidebar = false) =>
      i > 0 && isSidebar
        ? [
            [300, 600],
            [300, 250],
          ]
        : [300, 250]

    const articleBodyMpuSize = (i) => {
      if (windowWidth < TABLET_BREAKPOINT) {
        return [[300, 250]]
      }
      if (windowWidth <= maxLaptopBreakPoint) {
        return [
          [728, 90],
          [300, 250],
        ]
      }
      if (windowWidth >= LAPTOP_MEDIUM_BREAKPOINT) {
        return [
          [970, 250],
          [728, 90],
          [300, 250],
        ]
      }
      return mpuSize(i)
    }

    const isInskin = windowWidth >= INSKIN_BREAKPOINT ? 'true' : 'false'

    const defineSlot = (
      id,
      size,
      pos,
      isInskin = 'false',
      customSlot = false,
    ) =>
      googletag
        .defineSlot(customSlot || slot, size, id)
        ?.setCollapseEmptyDiv(!0) // ?. guards against defineSlot returning null upon failing to create a slot
        .setTargeting('tile', id)
        .setTargeting('pos', pos)
        .setTargeting('rfs', 0)
        .setTargeting('refresh', allowRefresh(size))
        .setTargeting('fn_pageskin', isInskin)
        .addService(googletag.pubads())

    googletag.cmd.push(function () {
      const deviceClass =
        windowWidth <= TABLET_BREAKPOINT
          ? ':not(.desktop-only)'
          : ':not(.mobile-only)'
      if (isSubscriber) {
        if (
          document.querySelector(`[id="${GPT_WALLPAPER_LITE}"]${deviceClass}`)
        ) {
          liteWallpaper = defineSlot(
            GPT_WALLPAPER_LITE,
            wallPaperSize,
            GPT_WALLPAPER,
            isInskin,
          )
        }
        if (
          document.querySelector(`[id="${GPT_SEEDTAG_LITE}"]${deviceClass}`)
        ) {
          liteSeedtag = defineSlot(
            GPT_SEEDTAG_LITE,
            seedTagSize,
            GPT_SEEDTAG_LITE,
          )
        }
        if (
          !isAdFree &&
          document.querySelector(`[id="${GPT_NATIVE_LITE}"]${deviceClass}`)
        ) {
          liteNative = defineSlot(GPT_NATIVE_LITE, nativeSize, GPT_NATIVE)
        }
        if (
          document.querySelector(`[id="${GPT_TEADSIB_LITE}"]${deviceClass}`)
        ) {
          liteTeadsib = defineSlot(GPT_TEADSIB_LITE, teadSibSize, GPT_TEADSIB1)
        }
        if (
          document.getElementById(GPT_BOTTOMBANNER_LITE) &&
          windowWidth <= TABLET_BREAKPOINT
        ) {
          liteBottomBanner = defineSlot(
            GPT_BOTTOMBANNER_LITE,
            bottomBannerSize,
            'bottom',
          )
        }
        if (
          document.querySelector(`[id="${GPT_TOPBANNER_LITE}"]${deviceClass}`)
        ) {
          liteTopBanner = defineSlot(
            GPT_TOPBANNER_LITE,
            topBannerSize,
            'top',
            isInskin,
          )
        }
      } else {
        if (document.querySelector(`[id="${GPT_WALLPAPER}"]${deviceClass}`)) {
          wallpaper = defineSlot(
            GPT_WALLPAPER,
            wallPaperSize,
            GPT_WALLPAPER,
            isInskin,
          )
        }
        if (document.querySelector(`[id="${GPT_SEEDTAG}"]${deviceClass}`)) {
          seedtag = defineSlot(GPT_SEEDTAG, seedTagSize, GPT_SEEDTAG)
        }
        if (document.querySelector(`[id="${GPT_NATIVE}"]${deviceClass}`)) {
          native = defineSlot(GPT_NATIVE, nativeSize, GPT_NATIVE)
        }
        if (document.querySelector(`[id="${GPT_TEADSIB1}"]${deviceClass}`)) {
          teadsib1 = defineSlot(GPT_TEADSIB1, teadSibSize, GPT_TEADSIB1)
        }
        if (document.querySelector(`[id="${GPT_TEADSIB2}"]${deviceClass}`)) {
          teadsib2 = defineSlot(GPT_TEADSIB2, teadSibSize, GPT_TEADSIB2)
        }
        if (
          document.getElementById(GPT_BOTTOMBANNER) &&
          windowWidth <= TABLET_BREAKPOINT
        ) {
          bottomBanner = defineSlot(
            GPT_BOTTOMBANNER,
            bottomBannerSize,
            'bottom',
          )
        }
        if (document.querySelector(`[id="${GPT_TOPBANNER}"]${deviceClass}`)) {
          topBanner = defineSlot(GPT_TOPBANNER, topBannerSize, 'top', isInskin)
        }
      }

      if (document.querySelector(`[id="${ADSLOT_PHOTO_TOP}"]`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_TOP, topBannerSize, ADSLOT_PHOTO_TOP),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_BOTTOM}"]`)) {
        photoAds.push(
          defineSlot(
            ADSLOT_PHOTO_BOTTOM,
            bottomBannerSize,
            ADSLOT_PHOTO_BOTTOM,
          ),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_MPU1}"]${deviceClass}`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MPU1, [300, 250], ADSLOT_PHOTO_MPU1),
        )
      }
      if (document.querySelector(`[id="${ADSLOT_PHOTO_MPU2}"]${deviceClass}`)) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MPU2, [300, 250], ADSLOT_PHOTO_MPU2),
        )
      }
      if (
        document.querySelector(`[id="${ADSLOT_PHOTO_MOBILE}"]${deviceClass}`)
      ) {
        photoAds.push(
          defineSlot(ADSLOT_PHOTO_MOBILE, [300, 250], ADSLOT_PHOTO_MOBILE),
        )
      }
      if (
        document.querySelector(`[id="${ADSLOT_PHOTO_SIDEBAR}"]${deviceClass}`)
      ) {
        photoAds.push(
          defineSlot(
            ADSLOT_PHOTO_SIDEBAR,
            [
              [300, 600],
              [300, 250],
            ],
            ADSLOT_PHOTO_SIDEBAR,
          ),
        )
      }

      document
        .querySelectorAll(`[id^="${GPT_BILLBOARD}"]`)
        .forEach((el, index) => {
          billboards.push(
            defineSlot(el.id, billboardSize, 'adSection' + index + 1),
          )
        })

      if (isPremiumArticleLayout && windowWidth > maxLaptopBreakPoint) {
        liteArticleAdSlots = liteArticleAdSlot$.reduce((a, id, i) => {
          const position = i + 1
          a.push(defineSlot(id, billboardSize, 'adSection' + position + 1))
          return a
        }, [])
      } else if (isPremiumArticleLayout && windowWidth <= maxLaptopBreakPoint) {
        liteArticleAdSlots = liteArticleAdSlot$.reduce((a, id, i) => {
          const position = i + 1
          a.push(defineSlot(id, [300, 250], 'adSection' + position + 1))
          return a
        }, [])
      } else if (
        !JSGlobals.domain.includes(widerArticleSites) &&
        windowWidth <= maxLaptopBreakPoint
      ) {
        const slots = isSubscriber ? [liteArticleMPU] : [articleMPU]
        mpus = [].concat(...slots).reduce((a, id, i) => {
          const position = i + 1
          a.push(defineSlot(id, mpuSize(position), `mpu${position}`))
          return a
        }, [])
      } else {
        const sidebarSize = isSubscriber
          ? liteSidebarMPU.length
          : sidebarMPU.length
        const slots = isSubscriber
          ? [liteSidebarMPU, liteArticleMPU]
          : [sidebarMPU, articleMPU]
        mpus = [].concat(...slots).reduce((a, id, i) => {
          let position = i + 1
          if (sidebarSize === 3 && sidebarPriorityAllocation.length > 0) {
            position = sidebarPriorityAllocation.shift()
          }
          const isSidebar = id.toLowerCase().includes('sidebar')
          const size =
            id.includes('article') &&
            JSGlobals.domain.includes(widerArticleSites)
              ? articleBodyMpuSize(position, id)
              : mpuSize(position, isSidebar)
          a.push(defineSlot(id, size, 'mpu' + position))
          return a
        }, [])
      }

      mpuBlog = articleLiveblogMPU.reduce((a, id, i) => {
        const position = i + 1
        a.push(defineSlot(id, [300, 250], 'blog' + position))
        return a
      }, [])

      sectionMpus = sectionMPU.reduce((a, id, i) => {
        const position = i + 1
        const size =
          /^mpuD/.test(id) && windowWidth > TABLET_BREAKPOINT
            ? [300, 600]
            : [300, 250]
        a.push(defineSlot(id, size, `mpu${position}`))
        return a
      }, [])

      leaderboards = leaderboard$.reduce((a, id, i) => {
        const position = i + 1
        let leaderboardSize
        if (isLongPagePhotoArticleSite && (position === 2 || position === 5)) {
          leaderboardSize = leaderboardSingleMobileSize
        } else {
          leaderboardSize = leaderboardMultiMobileSize
        }
        a.push(defineSlot(id, leaderboardSize, 'extralb' + position))
        return a
      }, [])

      adRows = adRowSection$.reduce((a, id) => {
        a.push(defineSlot(id, adSlot3x3Size, id))
        return a
      }, [])

      if (windowWidth > maxLaptopBreakPoint) {
        onscroll = onscroll$.reduce((a, id) => {
          a.push(defineSlot(id, onscrollSize, id))
          return a
        }, [])
      }

      // Disable initial load to precisely control when ads are requested
      googletag.pubads().disableInitialLoad()

      ppid()
    })

    const customTargetingParams = {
      articletype: articleType,
      deviceType: windowWidth >= DESKTOP_ADS_BREAKPOINT ? 'desktop' : 'mobile',
      pagetype: pageType,
      section: topLevelSection,
      subscriber: subscriberResource,
    }

    if (article) {
      customTargetingParams.topics = article.topics?.map((topic) => topic.name)
      customTargetingParams.sensitive = article.sensitive
      customTargetingParams.premium = article.premium
      customTargetingParams.affiliateArticle = article.isAffiliated
      customTargetingParams.pageId = getPageId(article)
    }

    googletag.cmd.push(function () {
      // set targeting here instead of the slot to optimise the ad's url size
      setCustomTargetParam(JSGlobals, customTargetingParams)

      if (window.apDataKeyValues) {
        for (var key in window.apDataKeyValues) {
          if (
            Object.prototype.hasOwnProperty.call(window.apDataKeyValues, key)
          ) {
            googletag.pubads().setTargeting(key, window.apDataKeyValues[key])
          }
        }
        // Capture Audience Project Values in dataLayer for lotame
        const audienceProjectValues = {
          audienceProjectValues: { ...window.apDataKeyValues },
        }
        // Ensure the Audience Project Values are always in the third position
        window.dataLayer.splice(3, 0, audienceProjectValues)
      }

      addCarbonSegmentToGPT()

      const filteredSlots = (() => {
        if (isSubscriber) {
          return [
            liteWallpaper,
            liteSeedtag,
            liteNative,
            liteTeadsib,
            liteTopBanner,
            ...mpus,
            ...mpuBlog,
            liteBottomBanner,
            ...sectionMpus,
            ...liteArticleAdSlots,
            ...leaderboards,
            ...adRows,
            ...billboards,
            ...photoAds,
          ]
        }

        return [
          wallpaper,
          seedtag,
          native,
          teadsib1,
          teadsib2,
          ...mpus,
          ...mpuBlog,
          topBanner,
          bottomBanner,
          ...sectionMpus,
          ...onscroll,
          ...liteArticleAdSlots,
          ...leaderboards,
          ...adRows,
          ...billboards,
          ...photoAds,
        ]
      })().filter(Boolean)

      // Define Opt Out script ad slots if user has rejected consent
      if (typeof secondLevelDomainName === 'string') {
        const ootag = window.ootag || {}
        ootag.queue = ootag.queue || []

        const slotIds = filteredSlots.map((slot) => slot.getSlotElementId())

        slotIds.forEach((slotId) =>
          ootag.queue.push(() => {
            ootag.defineSlot({
              adSlot: `${secondLevelDomainName}_${slotId}`,
              targetId: `${slotId}`,
            })
          }),
        )
        ootag.makeRequests()

        // Otherwise run GT as normal
      } else {
        filteredSlots.forEach((slot) => googletag.display(slot))

        resolve([filteredSlots, windowWidth, topLevelSection])
      }
    })
  })
}

function isScrolledIntoView(el) {
  var rect = el.getBoundingClientRect()
  var elemTop = rect.top
  var elemBottom = rect.bottom
  return elemTop < window.innerHeight && elemBottom >= 0 // partially visible
}

function RefreshImpressionViewable(slot, prebidCallback) {
  this.googletag = window.googletag || {}
  this.slot = slot
  this.interval = 20000
  this.count = 0
  // only inews uses maxrefresh and quota
  this.maxRefresh = 6
  this.quota = 6
  this.refresh = this.refresh.bind(this)
  this.scrollListener = null
  this.timer = null
  this.requestPrebid = prebidCallback
}

RefreshImpressionViewable.prototype.isViewable = function (el) {
  if (isScrolledIntoView(el)) {
    if (!this.timer) {
      this.timer = setTimeout(this.refresh, this.interval)
    }
  } else {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }
}

RefreshImpressionViewable.prototype.init = function () {
  this.timer = setTimeout(this.refresh, this.interval)
  this.scrollListener = this.isViewable.bind(
    this,
    document.getElementById(this.slot.getSlotElementId()),
  )
  window.addEventListener('scroll', this.scrollListener)
}

RefreshImpressionViewable.prototype.refresh = function () {
  this.count++
  this.slot.setTargeting('rfs', this.count)
  if (this.requestPrebid) {
    this.requestPrebid([[this.slot]])
  } else {
    this.googletag.pubads().refresh([this.slot])
  }
  window.removeEventListener('scroll', this.scrollListener)
}

RefreshImpressionViewable.prototype.dispose = function () {
  window.removeEventListener('scroll', this.scrollListener)
}

export const refreshImpressionViewable = (prebid) => {
  var googletag = window.googletag || {}
  let cached = {}

  let skipSlots = []

  window.addEventListener(
    'message',
    function (e) {
      try {
        const data = JSON.parse(e.data)

        if (data.skipRefreshSlot) {
          skipSlots = [...data.skipRefreshSlot]
        }
      } catch (e) {
        // noop
      }
    },
    false,
  )

  googletag.pubads().addEventListener('impressionViewable', function (event) {
    if (!event.isEmpty) {
      const id = event.slot.getSlotElementId()
      const {
        pos: [posAd],
      } = event.slot.getTargetingMap()
      const sizes = event.slot
        .getSizes()
        .map((size) =>
          size.length === 1
            ? size.map((x) => Object.values(x))
            : Object.values(size),
        )

      if (
        !sizes.some((size) =>
          ALLOWED_REFRESH_SIZE.some(
            (x) => JSON.stringify(x) === JSON.stringify(size),
          ),
        )
      ) {
        return
      }

      if (skipSlots.includes(posAd)) {
        return
      }

      if (!cached[id]) {
        cached[id] = new RefreshImpressionViewable(event.slot, prebid)
        cached[id].init()
      } else if (cached[id].count > 0) {
        cached[id].init()
      } else {
        cached[id].dispose()
      }
    }
  })
}

export default gpt
