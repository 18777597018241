/* globals JSGlobals googletag */
import { isThirdPartyModeEnabled } from '~/util/speed-tool'

const APSTAG_PROJECT_ALIAS = 'lotame'
const { isEnabled, enabledValues } = isThirdPartyModeEnabled()
const isScriptEnabled = enabledValues.includes(APSTAG_PROJECT_ALIAS)

// Provided by lotame, used to be initiated in GTM
export const initLotame = () => {
  if (isEnabled && !isScriptEnabled) {
    return
  }

  !(function () {
    window.googletag = window.googletag || {}
    window.googletag.cmd = window.googletag.cmd || []
    var audTargetingKey = 'lotame'
    var pidTargetingKey = 'lpid'
    var lotameClientId = JSGlobals.lotameId
    var audLocalStorageKey = 'lotame_' + lotameClientId + '_auds'
    // Immediately load audiences and id from local storage
    try {
      var storedAuds = window.localStorage.getItem(audLocalStorageKey) || ''
      if (storedAuds) {
        googletag.cmd.push(function () {
          window.googletag
            .pubads()
            .setTargeting(audTargetingKey, storedAuds.split(','))
        })
      }
      var localStoragePid = window.localStorage.getItem('_cc_id') || ''
      if (localStoragePid) {
        googletag.cmd.push(function () {
          window.googletag
            .pubads()
            .setTargeting(pidTargetingKey, localStoragePid)
        })
      }
      // eslint-disable-next-line
    } catch (e) {}
    // Callback when targeting audience is ready to push latest audience data
    var audienceReadyCallback = function (profile) {
      // Get audiences as an array
      var lotameAudiences = profile.getAudiences() || []
      // Set the new target audiences for call to Google
      googletag.cmd.push(function () {
        window.googletag.pubads().setTargeting(audTargetingKey, lotameAudiences)
      })
      var lotamePid = profile.getProfileId() || ''
      if (lotamePid) {
        googletag.cmd.push(function () {
          window.googletag.pubads().setTargeting(pidTargetingKey, lotamePid)
        })
      }
    }
    // Lotame Config
    var lotameTagInput = {
      data: {},
      config: {
        clientId: Number(lotameClientId),
        audienceLocalStorage: audLocalStorageKey,
        onProfileReady: audienceReadyCallback,
      },
    }
    // Lotame initialization
    var lotameConfig = lotameTagInput.config || {}
    var namespace = (window['lotame_' + lotameConfig.clientId] = {})
    namespace.config = lotameConfig
    namespace.data = lotameTagInput.data || {}
    namespace.cmd = namespace.cmd || []
  })()
  ;(function (d) {
    var file =
      'https://tags.crwdcntrl.net/lt/c/' + JSGlobals.lotameId + '/lt.min.js'
    var ref = d.getElementsByTagName('script')[0]
    var js = d.createElement('script')
    js.src = file
    ref.parentNode.insertBefore(js, ref)
  })(document)
}
