import Component from '@brightsites/flow-core/lib/component/layout/Component'
import { applyUrlParams } from '@brightsites/flow-core/lib/services/helper/url'
import last from 'ramda/src/last'
import React from 'react'
import { Helmet } from 'react-helmet'
import striptags from 'striptags'
import styled from 'styled-components'
import { dynamicVideoPreviewSites } from '~/config/JPIMConfig'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import { DAILYMOTION_TYPE } from '~/constants/ads'
import Audio from '~/library/Article/Audio'
import Camera from '~/library/Article/Camera'
import { DynamicVideoPreview } from '~/library/Article/Embeds/DynamicVideoPreview'
import Live from '~/library/Article/Live'
import StarRating from '~/library/Article/Products/StarRating'
import Video from '~/library/Article/Video'
import { Img } from '~/library/Img'
import {
  buildSizeUpdateParam,
  buildSrcSet,
  capImageWidths,
} from '~/library/helper'
import {
  ArticleData,
  ArticleSnippetData,
  Author,
  FC,
  ImageData,
  Ratio,
  SectionComponentType,
} from '~/types'
import ArticleHeadline from './ArticleHeadline'
import ArticleIcon from './ArticleIcon'
import ArticleTitle from './ArticleTitle'
import { Capsule } from './Capsule'
import HeroPlusLinksContent from './HeroPlusLinksContent'
import { getClassName, getHeroSlots } from './utils/get-hero-slots'
import Lead from '~/section/Lead'

export const ArticleHeadlineItem = styled(ArticleHeadline)`
  font-size: 16px;
  flex-direction: column;
  margin: 0 0 0 12px;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.titleFont};
  justify-content: start;

  &.hidden-image {
    margin: 0 !important;
  }

  > .standfirst,
  .standfirst-link {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-weight: normal;
  }
`

const AuthorName = styled(({ className, authors }) => {
  const title = authors[0].name || authors[0].firstName + authors[0].lastName
  return authors[0].path ? (
    <a className={`${className} author-name`} href={authors[0].path}>
      {title}
    </a>
  ) : (
    <span className={`${className} author-name`}>{title}</span>
  )
})`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.componentFont};
`

export const OpinionAuthorName = styled(AuthorName)`
  font-size: 14px;
  margin-top: 8px;
  font-style: italic;
  color: ${({ theme, hierarchyName, article }) => {
    if (theme.domain === 'farminglife.com') {
      return getPrimaryColorBasedOffAllSections(theme, article?.sections)
    }
    return getSectionPrimaryColor(theme, hierarchyName)
  }};
`

const Overlay = styled.a`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`

type ArticleItemProps = {
  className?: string
  isHeroRelated?: boolean
  ratio?: Ratio
  secondaryImage?: ImageData
  srcset?: number[]
  data: ArticleData
  isRemotePublication?: boolean
  domain?: string
  manualListName?: string
  maxTitleLength?: number
  type?: SectionComponentType
  useAuthorImage?: boolean
  useSpecialEventStyling?: boolean
  isCard?: boolean
  isMultiArticlePlusList?: boolean
  layout?: 'Standard' | 'Large' | 'Featured'
  isFeaturedx4?: boolean
  useImageCarousel?: boolean
  includeStandfirst?: boolean
  hideImage?: boolean
}

const ArticleItem = styled<FC<ArticleItemProps>>(
  ({
    className = '',
    isHeroRelated,
    ratio,
    secondaryImage,
    srcset,
    includeStandfirst = false,
    hideImage,
    data,
    domain = '',
    isRemotePublication = false,
    isCard,
    manualListName,
    maxTitleLength,
    type,
    useAuthorImage,
    isMultiArticlePlusList = false,
    isFeaturedx4,
    useImageCarousel = false,
    useSpecialEventStyling = false,
  }) => {
    const {
      articleAuthorImage,
      articleOpinion,
      articleTypes,
      authors,
      capsuleHighlight,
      hero,
      heroSlotTwo,
      heroSlotThree,
      hierarchy,
      isBreakingNews,
      exclusiveArticle,
      lead,
      starRating,
      path,
      photoArticle,
      relatedLinks,
      teaser,
      title,
      titleShort,
      sponsorName,
      liveblog,
      updated,
    } = data

    const isOpinion = articleTypes?.[0]?.name === 'Opinion'
    const isAudioArticle = articleTypes?.[0]?.name === 'Audio'
    const isAnalysisArticle = articleTypes?.[0]?.name === 'Analysis'
    const isExplainerArticle = articleTypes?.[0]?.name === 'Explainer'
    const timeDifference = Date.now() / 1000 - updated
    const hierarchyName = hierarchy?.[0]?.name
    const heroWithDailymotion = hero?.find(
      ({ type }) => type === DAILYMOTION_TYPE,
    )
    const hasHeroVideo = !!heroWithDailymotion
    const dailymotionId = heroWithDailymotion?.data.id

    const { heroSlots, heroImageCount } = getHeroSlots(
      useImageCarousel,
      hero,
      heroSlotTwo,
      heroSlotThree,
    )

    return (
      <Component
        data={{ type }}
        className={[
          'article-item',
          className,
          teaser,
          isBreakingNews && timeDifference < 7200 ? 'breaking-news' : '',
          isCard ? 'hasCard' : '',
        ]
          .filter((x) => !!x)
          .join(' ')}
        data-type="article-item"
        id="article-item"
        req={{
          flowpreview: true,
          hydrated: false,
          flowComponentFocused: -1,
          flowComponentHighlighted: -1,
        }}
        useSpecialEventStyling={useSpecialEventStyling}
        isRemotePublication={isRemotePublication}
      >
        {hasHeroVideo &&
        isMultiArticlePlusList &&
        dynamicVideoPreviewSites.includes(domain) ? (
          <DynamicVideoPreview
            dataId={dailymotionId}
            domain={domain}
            path={path}
          />
        ) : (
          !hideImage && (
            <ImageSelector
              title={title}
              articleIcon={ArticleIcon(
                photoArticle,
                hero?.some(({ type }) => type === DAILYMOTION_TYPE),
                isAudioArticle,
              )}
              heroSlots={heroSlots}
              heroImageCount={heroImageCount}
              path={path}
              srcset={srcset}
              useAuthorImage={useAuthorImage}
              articleAuthorImage={articleAuthorImage}
              authors={authors}
              ratio={ratio}
              isRemotePublication={isRemotePublication}
            />
          )
        )}

        {!isHeroRelated && (
          <ArticleHeadlineItem
            className={`${hideImage ? 'hidden-image ' : ''}article-headline`}
          >
            {hierarchy &&
              !isRemotePublication &&
              !useAuthorImage &&
              !articleOpinion &&
              type === 'ArticleX4Small' && (
                <Capsule
                  hierarchy={hierarchy}
                  is4XSmall
                  isActiveLiveBlog={liveblog && liveblog?.data?.status === 1}
                  isBreakingNews={isBreakingNews}
                  isExclusiveArticle={exclusiveArticle}
                  sponsorName={sponsorName}
                  updated={updated}
                />
              )}
            {hierarchy &&
              !isRemotePublication &&
              !useAuthorImage &&
              !articleOpinion &&
              type !== 'ArticleX4Small' && (
                <Capsule
                  hierarchy={hierarchy}
                  isActiveLiveBlog={liveblog && liveblog?.data?.status === 1}
                  isBreakingNews={isBreakingNews}
                  isExclusiveArticle={exclusiveArticle}
                  path={path}
                  sponsorName={sponsorName}
                  updated={updated}
                />
              )}
            {(useAuthorImage || articleOpinion) && authors[0]?.imageUrl && (
              <AuthorName authors={authors} article={data} />
            )}
            <ArticleTitle
              path={path}
              hierarchy={hierarchy}
              title={title}
              isAnalysis={isAnalysisArticle}
              isExplainer={isExplainerArticle}
              titleShort={titleShort}
              maxTitleLength={maxTitleLength}
              className={['article-title', isOpinion ? 'opinion' : ''].join(
                ' ',
              )}
              isOpinion={isOpinion}
              isRemotePublication={isRemotePublication}
              manualListName={manualListName}
              article={data}
            />
            <Lead
              type={type}
              lead={lead}
              isCard={isCard}
              path={path}
              includeStandfirst={includeStandfirst}
              isFeaturedx4={isFeaturedx4}
              isRemotePublication={isRemotePublication}
            />
            {(isOpinion || type === 'HeroArticle') && (
              <OpinionAuthorName
                hierarchyName={hierarchyName}
                authors={authors}
                article={data}
              />
            )}
            {!!starRating && (
              <StarRating
                className="section"
                rating={starRating}
                withoutPercentage={true}
              />
            )}
          </ArticleHeadlineItem>
        )}
        {type === 'ArticleX4' && isCard && (
          <Overlay className="overlay" href={path} />
        )}
        <HeroPlusLinksContent
          isHeroRelated={isHeroRelated}
          capsuleHighlight={capsuleHighlight}
          relatedLinks={relatedLinks}
          maxTitleLength={maxTitleLength}
          title={title}
          titleShort={titleShort}
          path={path}
          secondaryImage={secondaryImage}
          standfirst={lead}
        />
      </Component>
    )
  },
)`
  display: flex;
  margin: ${({ useSpecialEventStyling }) =>
    useSpecialEventStyling ? '10px' : '0'};

  :last-child {
    padding-bottom: 0;
  }
  &#article-item::before {
    display: none;
  }

  .standfirst-wrapper {
    .standfirst,
    .standfirst-link {
      font-size: 0.85rem;
      line-height: 1.25rem;
      margin: 0;
      font-family: ${({ theme }) => theme.primaryFont};
      color: ${({ isRemotePublication, theme, layout }) =>
        isRemotePublication
          ? theme.lightContrast
          : layout === 'Large'
          ? theme.lightGreyContrast
          : theme.fontColorMedium};
    }
    .standfirst p {
      margin: 0;
    }
    .standfirst-link {
      text-decoration: none;
    }
  }

  .image-wrap {
    display: flex;
    flex: 0 0 84px;
    flex-direction: column;
  }
  .article-item-image {
    padding-top: 100%;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    display: block;

    ${Camera}, ${Video}, ${Audio}, ${Live} {
      position: absolute;
      bottom: 5px;
      left: 5px;
      background-color: ${({ theme }) => theme.lightContrast};
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        width: 60%;
        fill: ${({ theme }) => theme.fontColor};
        stroke: transparent;
      }
    }
    ${Video}, ${Audio} {
      > svg {
        width: 80%;
      }
    }
    ${Live} {
      width: 16px;
      height: 10px;
      font-weight: normal;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    a:hover {
      opacity: 0.8;
    }
  }
  img {
    object-fit: cover;
  }
`

const ImagePlaceholder = styled(({ className, ...props }) => (
  <Img
    className={`${className} placeholder fill-layout`}
    src="/img/placeholder.png"
    width="288"
    height="185"
    {...props}
  />
))`
  &.placeholder {
    object-fit: contain;
  }
`

type ImageSelectorProps = {
  heroSlots?: ArticleSnippetData[] | null
  title: string
  path: string
  srcset?: number[]
  articleIcon?: JSX.Element | null
  useAuthorImage?: boolean
  authors?: Author[]
  articleAuthorImage?: object
  isRemotePublication?: boolean
  ratio?: Ratio
  heroImageCount?: number
}

export const ImageSelector = ({
  heroSlots,
  title,
  path,
  srcset: widths = [375, 640],
  articleIcon = null,
  useAuthorImage,
  authors,
  articleAuthorImage,
  isRemotePublication,
  ratio,
  heroImageCount = 1,
}: ImageSelectorProps) => {
  if (useAuthorImage || articleAuthorImage) {
    if (!authors?.[0]?.imageUrl) {
      return (
        <div className="image-wrap">
          <span className="article-item-image">
            <a
              href={path}
              title={title}
              target={isRemotePublication ? '_blank' : '_self'}
              rel="noopener noreferrer"
            >
              <ImagePlaceholder className="author-placeholder" />
            </a>
          </span>
        </div>
      )
    }
    return (
      <div className="image-wrap">
        <span className="article-item-image">
          <a
            title={title}
            href={path}
            target={isRemotePublication ? '_blank' : '_self'}
            rel="noopener noreferrer"
          >
            {authors.slice(0, 1).map(({ id, imageUrl, name }, index) => {
              const width = last(widths)?.toString()
              const urlParams = {
                ...buildSizeUpdateParam({ ratio }),
                ...(width && { width }),
              }
              return (
                <Img
                  key={id ?? index}
                  className="fill-layout"
                  src={imageUrl ?? ''}
                  urlParams={urlParams}
                  alt={name}
                  widths={widths}
                >
                  <ImagePlaceholder className="author-fallback" alt={name} />
                </Img>
              )
            })}
            {articleIcon}
          </a>
        </span>
      </div>
    )
  } else {
    if (!heroSlots?.some(({ type }) => type === 'image')) {
      return (
        <div className="image-wrap">
          <span className="article-item-image">
            <a
              href={path}
              title={title}
              target={isRemotePublication ? '_blank' : '_self'}
              rel="noopener noreferrer"
            >
              <ImagePlaceholder className="hero-placeholder" />
            </a>
          </span>
        </div>
      )
    }
    return (
      <div className={`${getClassName(heroImageCount)}`}>
        {heroSlots
          .filter((snippet): snippet is ImageData => snippet.type === 'image')
          .map(({ data: { id, url, isHeroImage, caption, extra } }, index) => {
            const alt = striptags(caption ?? '')
            const cappedWidths = capImageWidths({ widths, extra })
            const lastWidth = last(cappedWidths)?.toString()

            const urlParams = {
              ...buildSizeUpdateParam({
                ratio,
                sizes: extra?.sizes,
              }),
              quality: '70',
              ...(lastWidth && { width: lastWidth }),
            }

            return (
              <span className="article-item-image" key={id ?? index}>
                <a
                  href={path}
                  title={title}
                  target={isRemotePublication ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  {isHeroImage && (
                    <Helmet>
                      <link
                        rel="preload"
                        as="image"
                        href={applyUrlParams({ url, urlParams })}
                        imageSrcSet={buildSrcSet(url, cappedWidths, urlParams)}
                        fetchPriority="high"
                      />
                    </Helmet>
                  )}
                  <Img
                    className="fill-layout"
                    src={url}
                    urlParams={urlParams}
                    alt={alt}
                    width={cappedWidths[0]}
                    widths={cappedWidths}
                    isHeroImage={isHeroImage}
                  >
                    <ImagePlaceholder className="hero-fallback" />
                  </Img>
                  {articleIcon}
                </a>
              </span>
            )
          })}
      </div>
    )
  }
}

export default ArticleItem
