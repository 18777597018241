import striptags from 'striptags'

const generateEditedLead = (
  lead: string,
  includeStandfirst: boolean | null,
): string => {
  const strippedLead = striptags(lead)
  return includeStandfirst
    ? strippedLead.substring(0, 100) + (strippedLead.length > 100 ? '...' : '')
    : striptags(lead)
}

export default generateEditedLead
