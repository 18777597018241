import { partytownSnippet } from '@builder.io/partytown/integration'

export const initPartytown = () => {
  const element = document.createElement('script')
  element.type = 'text/javascript'
  element.innerHTML = partytownSnippet({ lib: '/~partytown/' })

  document.head.appendChild(element)
}

export const updatePartytown = () => {
  // if the script is added on the client-side, tell partytown it has been added
  if (typeof window !== 'undefined') {
    window.dispatchEvent(new CustomEvent('ptupdate'))
  }
}

export const jsWorkerLoader = ({
  src,
  scriptCode,
  attributes,
}: {
  src?: string
  scriptCode?: string
  attributes?: Record<string, any>
}) => {
  return new Promise((resolve, reject) => {
    if (!src && !scriptCode) {
      reject()
    }

    // scripts added in this way will be executed in a worker thread via @builder.io/partytown
    const element = document.createElement('script')
    element.type = 'text/partytown'
    element.onload = resolve
    element.onerror = reject

    if (src) {
      element.src = src
    }

    if (!src && scriptCode) {
      element.innerHTML = scriptCode
    }

    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        if (attributes[key]) {
          element.setAttribute(key, attributes[key])
        }
      })
    }

    document.head.appendChild(element)
  }).then(() => {
    updatePartytown()
  })
}

const _formatParams = (params: Array<any>) => {
  const noWrapParamType: Array<string> = ['number', 'boolean', 'object']

  return params.map((param) => {
    if (noWrapParamType.includes(`${typeof param}`)) {
      return param
    }

    return `'${param}'`
  })
}

export const addMethodToWorker = (
  func: CallableFunction,
  params?: Array<any>,
) => {
  // converts the function to a string and loads it in a worker thread
  // if there are params, they are passed to the function
  // used like this: addMethodToWorker(functionName, [param1, param2, ...])

  jsWorkerLoader({
    scriptCode: `(${func.toString()})(${
      params?.length ? _formatParams(params).join(', ') : ''
    })`,
  })
}
