import React from 'react'
import styled from 'styled-components'
import {
  device,
  getSectionPrimaryColor,
  getPrimaryColorBasedOffAllSections,
} from '~/config/theme/JPIMStyles'
import commentDisqusIcon from '~/icons/commentDisqusIcon'

const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 0 0 8px;
`

export const Button = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 25px;
  background: ${({ theme, hierarchy, article }) => {
    if (theme.domain === 'farminglife.com') {
      return getPrimaryColorBasedOffAllSections(theme, article?.sections)
    }
    return getSectionPrimaryColor(theme, hierarchy)
  }};
  padding: 0 14px;
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily?.openSans};
  text-decoration: none;
  scroll-behavior: smooth;
  scroll-margin: 5rem;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;

  .viafoura-comment-icon {
    width: 16px; /* Adjusted icon size */
    height: 16px; /* Adjusted icon size */
    display: flex;
    fill: ${({ theme }) => theme.lightContrast};
  }

  .viafoura {
    display: flex;
    color: ${({ theme }) => theme.lightContrast};
    background: transparent;
    font-size: 14px; /* Ensure text size is appropriate */
    font-weight: 700;
    margin-bottom: 1px;
    gap: 4px;

    .vf-comments-count {
      margin: 0 0 0 2px;
      word-break: normal;
    }

    #viafoura-comment-counter-wording {
      @media ${device.maxMobileM} {
        display: none;
      }
    }
  }
`

const ViafouraCommentButton = ({
  article,
  className,
  position,
  isCommentingEnabled,
  hierarchy,
}) => {
  if (!isCommentingEnabled) {
    return null
  }

  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined

  return (
    <Wrapper>
      <Button
        id="commentButtonViafoura"
        article={article}
        className={`${className} ${position} scroll-button`}
        hierarchy={hierarchyName}
      >
        <div
          className="viafoura-comment-icon"
          dangerouslySetInnerHTML={{ __html: commentDisqusIcon }}
        />
        <div className="viafoura comment-button">
          {/* wrapper necessary for counter observer */}
          <div className="viafoura comment-counter-wrapper">
            <vf-conversations-count />
          </div>
          <span id="viafoura-comment-counter-wording">Comment</span>
        </div>
      </Button>
    </Wrapper>
  )
}

export default ViafouraCommentButton
