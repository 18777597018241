export const smoothScroll = (containerId = 'comments') => {
  // Add the following class to buttons that need to smooth scroll
  const smoothScrollButtons = document.querySelectorAll('.scroll-button')
  const smoothScrollContainer = document.getElementById(containerId)

  const handleSmoothScroll = (e: Event) => {
    e.preventDefault()
    if (smoothScrollContainer) {
      // Calculate the target position (can change, ie. if Outbrain is adding content above it)
      const containerPosition = smoothScrollContainer.getBoundingClientRect()
      const scrollOffset = window.scrollY
      const offset = getOffsetOB()
      const targetPosition = containerPosition.top + scrollOffset - offset

      // TODO: Refactor this as the comment button no longer needs smooth scroll
      window.scrollTo({
        top: targetPosition,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        behavior: 'instant',
      })
    }
  }

  const getOffsetOB = () => {
    const windowWidth = window.innerWidth
    let offset
    switch (true) {
      case windowWidth < 520:
        offset = 80
        break
      case windowWidth < 1320:
        offset = 140
        break
      default:
        offset = 140
        break
    }
    return offset
  }

  smoothScrollButtons.forEach((button) => {
    button.addEventListener('click', handleSmoothScroll)
  })
}
