import { onLCP, onFID, onCLS } from 'web-vitals'

const reportWebVitals = () => {
  window.dataLayer = window.dataLayer || []

  const webVitals = {
    event: 'core web vitals',
    fid: '',
    lcp: '',
    cls: '',
  }

  const reportWebVital = (vital) => {
    webVitals[vital.name.toLowerCase()] = vital.value || ''
  }

  onCLS(reportWebVital)
  onFID(reportWebVital)
  onLCP(reportWebVital)

  let hasWebVitalFlushed = false

  const flushWebVitals = () => {
    if (!hasWebVitalFlushed) {
      window.dataLayer.push(webVitals)
      hasWebVitalFlushed = true
    }
  }

  window.addEventListener('visibilitychange', () => {
    flushWebVitals()
  })

  window.addEventListener('pagehide', () => {
    flushWebVitals()
  })
}

export default reportWebVitals
