/**
 * The function `getGdprConsentToken` interacts with the TCF API to retrieve the GDPR consent token and
 * gdprApplies value (true' if publisher has configured CMP to apply GDPR to all (including non-EEA) visitors)
 * and store them in `window.JSGlobals` if available.
 */
const removeEventListener = (listenerId: number) => {
  ;(window as Window).__tcfapi('removeEventListener', 2, () => {}, listenerId)
}

type TcData = {
  tcString: string
  gdprApplies: boolean
  eventStatus: string
  listenerId: number
}

export const getGdprConsentToken = () => {
  ;(window as Window).__tcfapi(
    'addEventListener',
    2,
    (tcData: TcData, success: boolean) => {
      if (
        success &&
        (tcData.eventStatus === 'tcloaded' ||
          tcData.eventStatus === 'useractioncomplete')
      ) {
        if (window.JSGlobals) {
          window.JSGlobals.consentString = tcData?.tcString
          window.JSGlobals.gdprApplies = tcData?.gdprApplies
          removeEventListener(tcData?.listenerId)
        } else {
          console.error('JSGlobals not available')
        }
      } else {
        console.error('GDPR consent string not available')
        removeEventListener(tcData?.listenerId)
      }
    },
  )
}
