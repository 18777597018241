/**
 * Retrieves the IDs of all elements with the class 'OUTBRAIN' and concatenates them into a single string.
 * Each ID is separated by a comma.
 *
 * @returns {string} A comma-separated string of all 'data-widget-id' attributes from elements with the class 'OUTBRAIN'.
 */
export const getOutbrainElementIds = (): string => {
  const outbrainElements = document.querySelectorAll<HTMLElement>('.OUTBRAIN')
  let outbrainIds = ''

  outbrainElements.forEach((element) => {
    const elementId = element.getAttribute('data-widget-id')
    if (elementId) {
      if (outbrainIds) {
        outbrainIds += ','
      }
      outbrainIds += elementId
    }
  })

  return outbrainIds
}
