const newsletterSuccessText = (configuration) => {
  const piano = configuration.piano
  const { subscription, adLite, premium } = piano
  const { isSubscriber } = configuration

  const brandName = configuration.name
  const isNewsletter = !isSubscriber && !subscription && !adLite && !premium

  if (adLite && !isSubscriber) {
    return `Did you know with an ad-lite subscription to ${brandName}, you get 70% fewer ads while viewing the news that matters to you.`
  }

  if (adLite && isSubscriber) {
    return `Did you know you can manage your profile, and explore all of the available newsletters from ${brandName} within your account.`
  }

  if (premium && !isSubscriber) {
    return `Did you know with a Digital subscription to ${brandName}, you can get access to all of our premium content, as well as benefiting from fewer ads, loyalty rewards and much more.`
  }

  if (premium && isSubscriber) {
    return `Did you know you can manage your profile, and explore all of the available newsletters from ${brandName} within your account.`
  }

  if (!isSubscriber && subscription) {
    return `Did you know with a Digital Subscription to ${brandName}, you can get unlimited access to the website including our premium content, as well as benefiting from fewer ads, loyalty rewards and much more.`
  }

  if (isSubscriber && subscription) {
    return `Did you know you can manage your profile, and explore all of the available newsletters from ${brandName} within your account.`
  }

  if (isNewsletter) {
    return `Did you know you can manage your profile, and explore all of the available newsletters from ${brandName} within your account.`
  }
}

export default newsletterSuccessText
