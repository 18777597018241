import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const Heading = styled.h4`
  font-family: ${(props) => props.theme.fontFamily.componentFont};
  margin: 0;
`

const PersonalityProfileContainer = styled.div`
  margin-top: -20px;
  display: ${({ theme }) =>
    peopleTagsWebsites.includes(theme.domain) ? 'block' : 'none'};
  @media ${device.maxMobileL} {
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px solid ${(props) => props.theme.greyContrast};
  }
`
const PeopleTagContainer = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  & > :first-child,
  > :nth-child(4) {
    margin: 5px 5px 5px 0;
  }
  @media ${device.maxMobileL} {
    & > :first-child,
    > :nth-child(3),
    & > :nth-child(5) {
      margin: 5px 5px 5px 0;
    }

    > :nth-child(4) {
      margin: 5px;
    }
  }
  @media ${device.maxMobileS} {
    & > :nth-child(2),
    & > :nth-child(5),
    > :nth-child(4) {
      margin: 5px 5px 5px 0;
    }
  }
`
const PeopleTag = styled.li`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e8e7;
  padding: 5px;
  border-radius: 25px;
  margin: 5px;
  position: relative;
  @media ${device.maxMobileL} {
    width: 44%;
  }
  @media ${device.maxMobileS} {
    width: 100%;
  }
`

const PeopleProfileLink = styled.a`
  width: 100%;
  height: 100%;
  font-family: ${(props) => props.theme.fontFamily.componentFont};
  color: ${(props) => props.theme.fontColor};
  text-decoration: none;
`

const PeopleTagImage = styled.div`
  margin: 0 auto 0 0;
  width: 100%;
  max-width: 20%;
  amp-img {
    display: block;
    border-radius: 50%;
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
  }
`
const PeopleTagInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const PeopleName = styled.p`
  max-width: 80%;
  width: 100%;
  margin: 0 5px;
  font-size: 14px;
`
export const peopleTagsWebsites = ['3addedminutes.com']

const PersonalityProfileTags = ({ people, isPlayer }) => {
  return (
    <PersonalityProfileContainer className="personality-profiles">
      <Heading>{isPlayer ? 'Players' : 'People'} in this article</Heading>
      <PeopleTagContainer>
        {people.map((person) => {
          return (
            <PeopleTag key={person.id} className="people-tag">
              <PeopleProfileLink
                className="people-profile-link"
                href={person.path}
              >
                <PeopleTagInner>
                  {person?.image?.data?.url && (
                    <PeopleTagImage>
                      <amp-img
                        src={person?.image?.data?.url}
                        height="1"
                        width="1"
                        layout="responsive"
                      />
                    </PeopleTagImage>
                  )}
                  {person?.name && (
                    <PeopleName className="people-profile-name">
                      {person?.name}
                    </PeopleName>
                  )}
                </PeopleTagInner>
              </PeopleProfileLink>
            </PeopleTag>
          )
        })}
      </PeopleTagContainer>
    </PersonalityProfileContainer>
  )
}

PersonalityProfileTags.propTypes = {
  people: PropTypes.array.isRequired,
  isPlayer: PropTypes.bool,
}

export default PersonalityProfileTags
