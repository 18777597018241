const accordion = function () {
  const acc = document.querySelectorAll('.accordion')
  const playBtn = document.querySelectorAll('.prev-puzzle-play-btn')
  const iframe = document.querySelector('#pa-iframe')
  const puzzleTypeTitle = document.querySelector('#puzzle-type-title')
  const prevPuzzleDate = document.querySelector('#prev-puzzle-date')
  let i

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function () {
      this.classList.toggle('active')
      const panel = this.nextElementSibling
      if (panel.style.display === 'flex') {
        panel.style.display = 'none'
      } else {
        panel.style.display = 'flex'
      }
    })
  }
  for (i = 0; i < playBtn.length; i++) {
    playBtn[i].addEventListener('click', function () {
      iframe.src = this.dataset.url
      puzzleTypeTitle.innerHTML = this.dataset.type
      prevPuzzleDate.innerHTML = this.dataset.date
      prevPuzzleDate.style.display = 'block'
    })
  }
}

export default accordion
