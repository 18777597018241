import { applyUrlParams } from '@brightsites/flow-core/lib/services/helper/url'
import React from 'react'
import styled from 'styled-components'
import { FC } from '~/types'
import { buildSrcSet } from './helper'

export type ImgProps = React.ComponentProps<'img'> & {
  children?: React.ReactNode
  urlParams?: Record<string, string>
  widths?: number[]
  isHeroImage?: boolean
}

const Picture = styled.picture`
  .responsive-img-hidden {
    display: none;
  }

  .fill-layout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

/**
 * An extended version of `<img>` supporting all the same props plus a few additional ones.
 * It also supports a fallback child element and a layout via `className="fill-layout"`
 *
 * @param props.className - Supported layouts: `'fill-layout'`
 * @param props.urlParams - URL parameters to be added to `src` and paths in `srcSet`
 * @param props.widths - Image widths to be used in `srcSet`. Takes precedence over `urlParams.width`
 * @param props.children - Image fallback in case of error loading `src`. Can be any element, including `<Img>`
 * @param props.srcSet - When provided, takes precedence, i.e. `widths` has no effect
 * @example
 *    <Img
 *      className="fill-layout"
 *      src="/example.jpg?some=param"
 *      urlParams={{other: 'param', width: '10'}}
 *      widths={[30, 50]} // values of `widths` will override `urlParams.width`
 *    />
 *
 *    <Img
 *      src="/example.jpg?some=param"
 *      urlParams={{other: 'param'}} // src="/example.jpg?some=param&other=param"
 *      widths={[30, 50]}
 *    >
 *      <img src="/fallback.jpg" />
 *    </Img>
 */
export const Img: FC<ImgProps> = ({
  src,
  urlParams,
  widths = [],
  children,
  sizes,
  srcSet,
  isHeroImage = false,
  ...rest
}) => {
  const imgProps: React.ComponentProps<'img'> = {
    src: src && applyUrlParams({ url: src, urlParams, unique: true }),
    decoding: 'async',
    loading: isHeroImage ? 'eager' : 'lazy',
    ...(isHeroImage && { fetchpriority: 'high' }),
    ...rest,
  }

  const sourceProps: React.ComponentProps<'source'> = {
    ...(sizes && { sizes }),
    ...(srcSet && { srcSet }),
  }

  if (src && !srcSet && widths.length > 0) {
    sourceProps.srcSet = buildSrcSet(src, widths, urlParams)
  }

  return (
    <Picture className="responsive-img">
      {sourceProps.srcSet && <source {...sourceProps} />}
      <img {...imgProps} />
      {children && (
        <span className="responsive-img-fallback responsive-img-hidden">
          {children}
        </span>
      )}
    </Picture>
  )
}
