/*globals googletag*/
export const CARBON_TAG_PARENT_ID = '46fa799f5b'

export const ampAdCarbonUrl = `https://amp-edge.ccgateway.net/v1/${CARBON_TAG_PARENT_ID}/at.json?curl=CANONICAL_URL&slot=ATTR(data-slot)&timeout=TIMEOUT&href=HREF&w=ATTR(width)&h=ATTR(height)&ow=ATTR(data-override-width)&oh=ATTR(data-override-height)&ms=ATTR(data-multi-size)&pvid=PAGEVIEWID&client=ADCID&dsp=1`

const getCarbon = () => {
  const carbon = JSON.parse(window.localStorage.getItem('ccRealtimeData'))
  if (!carbon) {
    return undefined
  }
  return carbon
}

export const getCarbonSegment = () => {
  const carbon = getCarbon()
  return carbon?.audiences?.map(({ id }) => id) || []
}

const getIabIds = () => {
  const iabIds = JSON.parse(window.localStorage.getItem('ccContextualData'))
  if (!iabIds) {
    return
  }
  return iabIds
}

export const addCarbonSegmentToGPT = () => {
  if (googletag.pubads().getTargeting('carbon_segment').length === 0) {
    const carbon_segment = getCarbonSegment()
    googletag.pubads().setTargeting('carbon_segment', carbon_segment)
  }
  if (googletag.pubads().getTargeting('cc-iab-class-id').length === 0) {
    const iabIds = getIabIds()
    googletag.pubads().setTargeting('cc-iab-class-id', iabIds)
  }
}

export const initCarbonTag = () => {
  // bootstrap
  !(function (a) {
    ;(a.cca = a.cca || {}), (a.cca.tapPrebidQue = []), (a.cca.tapGAMQue = [])
    var n = a.cca
    a.pbjs = a.pbjs || { que: [] }
    var t = a.pbjs
    t.que.push(function () {
      t.onEvent('bidWon', function (a) {
        n.tapReady || n.tapPrebidQue.push(JSON.stringify(a))
      })
    }),
      (a.googletag = a.googletag || { cmd: [] })
    var c = a.googletag
    c.cmd.push(function () {
      c.pubads().addEventListener('slotRenderEnded', function (a) {
        n.tapReady || n.tapGAMQue.push(a)
      })
    })
  })(window)
  // load tag
  !(function (a, l, b, c, k, s, t, g, A) {
    ;(a.CustomerConnectAnalytics = k),
      (a[k] =
        a[k] ||
        function () {
          ;(a[k].q = a[k].q || []).push(arguments)
        }),
      (g = l.createElement(b)),
      (A = l.getElementsByTagName(b)[0]),
      (g.type = 'text/javascript'),
      (g.async = !0),
      (g.src = c + '?id=' + s + '&parentId=' + t),
      A.parentNode.insertBefore(g, A)
  })(
    window,
    document,
    'script',
    '//carbon-cdn.ccgateway.net/script',
    'cca',
    window.location.hostname,
    CARBON_TAG_PARENT_ID,
  )
}
