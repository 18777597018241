import React from 'react'
import styled from 'styled-components'
import { CaptionInfoButton } from './CaptionInfoButton'
import { device } from '~/config/theme/JPIMStyles'
import { FC } from '~/types'

interface CaptionOverlayProps {
  children: JSX.Element
}

const CONTENT_SPACING = 8
const BUTTON_SIZE = 32
const BUTTON_POSITIONS = { sm: 16, lg: 24 }

const StyledOverlay = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  position: absolute;
  inset: auto 0 0 0;
  transition: opacity 0.3s ease;
  padding: 16px;
  padding-right: calc(
    10px + ${CONTENT_SPACING}px + ${BUTTON_SIZE}px + ${BUTTON_POSITIONS.sm}px
      // 10px accounts for negative margin in parent
  );
  pointer-events: none;
  background: rgba(29, 30, 31, 0.6);
  color: #fff;

  &.show {
    opacity: 1;
  }

  figcaption {
    padding: 0;
    margin-left: 5px;
  }

  @media ${device.laptop} {
    padding: 24px;
    padding-right: calc(
      ${CONTENT_SPACING}px + ${BUTTON_SIZE}px + ${BUTTON_POSITIONS.lg}px
    );
    figcaption {
      margin: 0;
    }
  }
`

export const CaptionOverlay: FC<CaptionOverlayProps> = ({ children }) => {
  return (
    <>
      <StyledOverlay className="caption-overlay">{children}</StyledOverlay>
      <CaptionInfoButton size={BUTTON_SIZE} positions={BUTTON_POSITIONS} />
    </>
  )
}
