import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  line-height: 0;
`

const Teadsib = (props) => <Wrapper {...props} />

export default Teadsib
