/** This function will check the href of all anchor elements
 *  to see if it is a skimlink. If it is, it will extract and
 *  decode the source link and set that as the HREF URL, otherwise
 *  it will simply return.
 */

export default () => {
  const anchorElements = document.querySelectorAll('.markup a')

  if (anchorElements.length === 0) {
    return
  }

  anchorElements.forEach((el) => {
    const originalUrl = el.getAttribute('href')

    const startOfUrlToBeExtracted = '&url='
    const endOfUrlToBeExtracted = '&sref='

    const startIndex =
      originalUrl.indexOf(startOfUrlToBeExtracted) +
      startOfUrlToBeExtracted.length
    const endIndex = originalUrl.indexOf(endOfUrlToBeExtracted)

    if (startIndex !== -1 && endIndex !== -1) {
      const extractedString = originalUrl.substring(startIndex, endIndex)
      const decodedUrl = decodeURIComponent(extractedString)

      el.setAttribute('href', decodedUrl)
      el.setAttribute('data-vars-el', decodedUrl)
      el.setAttribute('rel', 'noskim')
    }

    return
  })
}
