import styled, { createGlobalStyle } from 'styled-components'
import {
  fixedWidthSites,
  increasedWidthSites,
  weatherWidgetSites,
} from '~/config/JPIMConfig'
import { device } from '~/config/theme/JPIMStyles'
import {
  NatWorldTVLinkBgImg,
  NatWorldTVLinkBgImgHover,
} from '../NatWorldTVLinkBgImg'

type GlobalStyledProps = {
  theme?: {
    darkestContrast: string
  }
}

type HeaderStyledProps = {
  isSimplifiedHeaderSite: boolean
  theme?: {
    adContainerGrey: string
    breakingNewsGrey: string
    darkestContrast: string
    fontColorMedium: string
    greyContrast: string
    lightContrast: string
    primaryColor: string
    secondaryColor: string
    fontFamily: {
      openSans: string
      openSansSemiBold: string
    }
  }
}

export const GlobalStyle = createGlobalStyle<GlobalStyledProps>`
  body .tp-modal {
    max-height: 100vh;
    z-index: 2147483647 !important;

    .tp-close.tp-active {
      background: none;
      box-shadow: none;
      top: 5px;
      right: 5px;
    }

    .tp-active {
      .tp-close:hover, .tp-close:focus {
        box-shadow: none;
      }

      .tp-close:after {
        content: '\\2715';
        color: ${({ theme }) => theme.darkestContrast};
        font-size: 25px;
        padding: 6px;
      }
    }
  }

  .gssb_c .gsc-completion-container {
      position: fixed !important;
      top: 66px;
      max-width: 300px;
  }

  .overflow-hidden {
    overflow-y: hidden;
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.lightContrast};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow-x: hidden;

  *:focus {
    outline: grey 1px;
    outline-style: auto;
  }
`

export const TickerWrapper = styled.div`
  height: 48px;
`

export const CenterWrapper = styled.div<HeaderStyledProps>`
  position: relative;
  z-index: inherit;
  padding: 10px 18px 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 74px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.greyContrast};
  background-color: inherit;

  &.home {
    height: ${({ isSimplifiedHeaderSite }) => isSimplifiedHeaderSite && '60px'};
    @media ${device.tablet} {
      height: ${({ isSimplifiedHeaderSite }) =>
        isSimplifiedHeaderSite && '74px'};
    }
    @media ${device.laptop} {
      height: ${({ isSimplifiedHeaderSite }) =>
        isSimplifiedHeaderSite && '104px'};
    }
  }

  @media ${device.maxTabletS} {
    padding: 8px 18px;
    height: ${({ theme }) =>
      weatherWidgetSites.includes(theme.domain) ? '74px' : '60px'};
    margin: 0;
  }

  @media ${device.laptop} {
    padding: ${({ theme }) =>
      fixedWidthSites.includes(theme.domain) ? '18px 0' : '18px'};
    max-width: ${({ theme }) =>
      fixedWidthSites.includes(theme.domain) ? '1000px' : '100%'};
    border-bottom: none;
    margin: ${({ theme }) =>
      fixedWidthSites.includes(theme.domain) ? '0 auto' : '0'};
    position: relative;
  }
  @media ${device.laptopM} {
    max-width: ${({ theme }) =>
      increasedWidthSites.includes(theme.domain)
        ? '1300px'
        : fixedWidthSites.includes(theme.domain)
        ? '1000px'
        : '100%'};
  }
`

export const StyledRightWrapper = styled.div<HeaderStyledProps>`
  display: none;
  @media ${device.laptop} {
    flex-grow: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite ? 'unset' : '1'};
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const StyledHamburger = styled.div<HeaderStyledProps>`
  position: absolute;
  left: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSimplifiedHeaderSite, theme }) =>
    isSimplifiedHeaderSite ? theme.adContainerGrey : 'inherit'};
  border: ${({ isSimplifiedHeaderSite, theme }) =>
    isSimplifiedHeaderSite && `1px solid ${theme.greyContrast}`};
  padding: ${({ isSimplifiedHeaderSite }) =>
    isSimplifiedHeaderSite && '2px 4px'};

  .burger-text {
    color: ${({ theme }) => theme.darkContrast};
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    font-size: 14px;
    transition: opacity 0.3s;
    display: none;
    margin: 0 4px;
    @media ${device.tabletS} {
      display: inline-block;
    }
  }

  &:hover {
    button,
    .burger-text {
      opacity: 0.7;
    }
  }

  @media ${device.laptop} {
    left: ${({ theme }) =>
      fixedWidthSites.includes(theme.domain) ? '6px' : '18px'};
  }
`

export const MenuButton = styled.button`
  width: 24px;
  height: 24px;
  outline: none;
  transition: opacity 0.3s;

  & svg {
    vertical-align: top;
    fill: ${({ theme }) => theme.fontColorMedium};
  }
`

export const SearchButton = styled(MenuButton)<HeaderStyledProps>`
  width: 42px;
  height: 42px;
  margin-right: ${({ isSimplifiedHeaderSite }) =>
    isSimplifiedHeaderSite ? '0' : '12px'};
  position: relative;

  .search-circle {
    fill: ${({ isSimplifiedHeaderSite, theme }) =>
      isSimplifiedHeaderSite && theme.lightContrast};
  }
`

export const StyledLogoWrapper = styled.div<HeaderStyledProps>`
  text-align: center;
  max-width: 95%;
  @media ${device.laptop} {
    &.home svg {
      width: ${({ isSimplifiedHeaderSite }) =>
        isSimplifiedHeaderSite && 'auto'};
      height: ${({ isSimplifiedHeaderSite }) =>
        isSimplifiedHeaderSite && '64px'};
    }
  }
  @media ${device.laptop} {
    display: ${({ isSimplifiedHeaderSite }) =>
      !isSimplifiedHeaderSite && 'contents'};
  }
`

export const StyledTrustedCopy = styled.span<HeaderStyledProps>`
  display: none;
  color: #999999;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: ${({ isSimplifiedHeaderSite }) =>
    isSimplifiedHeaderSite ? '12px' : '14px'};

  &.hide {
    display: none;
  }
  @media ${device.tablet} {
    display: inline-block;
  }
  @media ${device.laptop} {
    margin-left: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite ? '0' : '25px'};
  }
`

export const StyledTodaysDate = styled.div<HeaderStyledProps>`
  display: none;

  @media ${device.tablet} {
    display: block;
    color: #999999;
    height: 22px;
    line-height: 22px;
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    font-size: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite ? '12px' : '14px'};

    &.hide {
      display: none;
    }
  }
`

export const Logo = styled.a<HeaderStyledProps>`
  height: auto;
  width: auto;
  max-height: 200px;
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    margin-left: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite ? '0' : '55px'};
  }
`

export const MenuContainer = styled.div`
  position: relative;
  height: 54px;
  background-color: ${({ theme }) =>
    fixedWidthSites.includes(theme.domain)
      ? theme.lightContrast
      : theme.domain === '3addedminutes.com'
      ? theme.secondaryColor
      : theme.primaryColor};
  display: none;

  @media ${device.laptop} {
    display: block;
    max-width: ${({ theme }) =>
      fixedWidthSites.includes(theme.domain) ? '1000px' : '100%'};
    margin: 0 auto;
    .navbar {
      display: flex;
      justify-content: space-between;
    }
  }
  @media ${device.laptopM} {
    max-width: ${({ theme }) =>
      increasedWidthSites.includes(theme.domain)
        ? '1300px'
        : fixedWidthSites.includes(theme.domain)
        ? '1000px'
        : '100%'};
  }
`

export const MenuContainerWrapper = styled.div<HeaderStyledProps>`
  @media ${device.laptop} {
    width: 100%;
    border-top: ${({ isSimplifiedHeaderSite, theme }) =>
      isSimplifiedHeaderSite
        ? `1px ${theme.breakingNewsGrey} solid`
        : fixedWidthSites.includes(theme.domain) &&
          `1px ${theme.greyContrast} solid`};
    border-bottom: ${({ isSimplifiedHeaderSite, theme }) =>
      isSimplifiedHeaderSite
        ? `1px ${theme.breakingNewsGrey} solid`
        : fixedWidthSites.includes(theme.domain) &&
          `2px ${theme.greyContrast} solid`};
  }
`

export const Search = styled.div<HeaderStyledProps>`
  position: relative;
  height: 40px;
  width: ${({ isSimplifiedHeaderSite }) => !isSimplifiedHeaderSite && '130px'};
  @media ${device.laptop} {
    width: ${({ isSimplifiedHeaderSite }) =>
      !isSimplifiedHeaderSite && '280px'};
  }

  /* override search base styling */
  #searchBox0 {
    position: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite ? 'absolute' : 'relative'};
    top: 0;
    right: 0;
    width: 280px;
  }

  .gsc-search-button-v2 {
    display: none;
  }
  .quick-searchresults {
    max-height: 0;
    overflow: hidden;
  }
  .gsc-search-box {
    margin-bottom: 0;
  }
  .gsc-input-box {
    height: 40px;
    display: flex;
    align-items: center;
    border-color: ${({ isSimplifiedHeaderSite }) =>
      isSimplifiedHeaderSite && '#999999'};
  }
  .gsst_a {
    padding: 0;
    text-decoration: none;
  }
  .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding-right: 2px;
    background-position: right !important;
  }
  .gsib_a {
    padding: 0;
  }
  .gsst_a .gscb_a {
    font-family: helvetica, sans-serif;
    top: 7px;
    position: relative;
    line-height: 0;
    font-size: 52px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 100;
  }
`

export const StyledPrimaryTopMenu = styled.div<HeaderStyledProps>`
  opacity: 1;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
  right: 0;
  top: 0;

  .hidden {
    width: 0;
    display: none;
  }

  & a,
  & a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.fontColorMedium};
  }

  @media ${device.laptop} {
    right: ${({ isSimplifiedHeaderSite }) =>
      !isSimplifiedHeaderSite && 'unset'};
    .scroll-direction-down & {
      opacity: 0;
      display: none;
    }
  }

  @media ${device.maxTablet} {
    display: none;
  }
`

export const SocialsWrapper = styled.div<HeaderStyledProps>`
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  right: ${({ isSimplifiedHeaderSite }) => isSimplifiedHeaderSite && '0'};
  @media ${device.laptop} {
    .scroll-direction-down & {
      opacity: 1;
      z-index: 1;
    }
  }
`

export const StyledShotsTV = styled.a`
  background-image: url(${NatWorldTVLinkBgImg});
  color: ${({ theme }) => theme.lightContrast} !important;
  background-color: #ff0000;
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  white-space: nowrap;
  min-width: 160px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 2px solid #ff0000;
  border-radius: 8px;
  padding: 1px 16px;
  margin: 0 0 0 4px;

  &:hover {
    background-image: url(${NatWorldTVLinkBgImgHover});
    color: #ff0000;
    background-color: #f9f8fd;
  }
`
