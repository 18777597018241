import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import { ArticleData } from '~/types'
import { convertHexToRGBA } from '~/util/convertHexColor'

export const getAvatarBackgroundColor = (theme: any, article?: ArticleData) => {
  if (theme.domain === 'yorkshirepost.co.uk') return 'rgba(255,198,0,0.8)'

  if (theme.domain === 'farminglife.com')
    return getPrimaryColorBasedOffAllSections(theme, article?.sections)

  return convertHexToRGBA(
    getSectionPrimaryColor(theme, article?.hierarchy?.[0]?.name),
    0.8,
  )
}
