import { domainsWithChartbeat } from '~/config/JPIMConfig'
import { isLogin, isPianoSubscriber } from '~/js/piano'
import { debug } from './debug'
import { jsWorkerLoader } from '~/js/util/workers'

const NATIONALWORLD_CHARTBEAT_ID = 56174

const subscriberStatus = () => {
  const guestStatus = 'anon'
  const registeredStatus = 'lgdin'
  const subscriberStatus = 'paid'

  const isUserLoggedIn = isLogin()
  const isUserSubscribed = isPianoSubscriber()

  // If user is logged in but not subscribed
  if (isUserLoggedIn && !isUserSubscribed) {
    return registeredStatus
  }

  // If user is logged and subscribed
  if (isUserLoggedIn && isUserSubscribed) {
    return subscriberStatus
  }

  // If user is not logged in
  return guestStatus
}

// Observe if paywall is shown
const observePaywall = (_cbq) => {
  const paywallObserver = new MutationObserver(() => {
    const paywall = document.querySelector(
      '#content-root-wrapper > .piano-article-paywall',
    )
    if (paywall) {
      _cbq.push(['trackPaywallShown'])
      paywallObserver.disconnect()
    }
  })
  paywallObserver.observe(document, { childList: true, subtree: true })
}

// Observe if newsletter signup is shown
const observeNewsletter = (_cbq) => {
  const bottomNewsletter = document.querySelector(
    '#newsletter-page-signup-form > div > .field-wrapper > input[name="loginEmail"]',
  )
  const sidebarNewsletter = document.querySelector(
    '#menu-newsletter-signup, #sidebar-newsletter-signup',
  )

  const trackNewsletterSignupShown = () => {
    _cbq.push(['trackNewsletterSignupShown'])
    console.log('Chartbeat - A user was shown a newsletter signup option')
  }

  if (sidebarNewsletter && !sidebarNewsletter.hasClickListener) {
    sidebarNewsletter.addEventListener('click', trackNewsletterSignupShown)
    sidebarNewsletter.hasClickListener = true
  }

  if (bottomNewsletter && !bottomNewsletter.hasClickListener) {
    bottomNewsletter.addEventListener('click', trackNewsletterSignupShown)
    bottomNewsletter.hasClickListener = true
  }
}

export const initChartbeat = () => {
  const currentDomain = window.JSGlobals?.domain

  if (domainsWithChartbeat.includes(currentDomain)) {
    const currentSections = window.JSGlobals?.article?.sections.join(',')
    const currentAuthors = window.JSGlobals?.article?.authors
      .map((author) => author.name.trim())
      .join(',')

    /** CONFIGURATION START **/
    const _sf_async_config = (window._sf_async_config =
      window._sf_async_config || {})
    _sf_async_config.uid = NATIONALWORLD_CHARTBEAT_ID
    _sf_async_config.domain = currentDomain
    _sf_async_config.useCanonical = true
    _sf_async_config.useCanonicalDomain = true
    _sf_async_config.sections = currentSections || ''
    _sf_async_config.authors = currentAuthors || ''
    _sf_async_config.noCookies = true
    /** CONFIGURATION END **/

    // Display Chartbeat subscriber status
    let _cbq = (window._cbq = window._cbq || [])
    _cbq.push(['_acct', subscriberStatus()])

    debug(
      jsWorkerLoader,
      'chartbeat',
    )({
      src: '/api/script?url=https://static.chartbeat.com/js/chartbeat.js',
    }).catch((error) => {
      console.error('Error loading Chartbeat: ', error)
    })

    observePaywall(_cbq) // Observe paywall
    observeNewsletter(_cbq) // Observe newsletter signup
  }
}

export const enableCookies = () => {
  if (window._sf_async_config) {
    window._sf_async_config.noCookies = false
  }
}
