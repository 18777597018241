import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  .tp-container-inner {
    margin: 20px auto;
  }
`

const PianoArticlePaywall = () => <Wrapper className="piano-article-paywall" />

export default PianoArticlePaywall
