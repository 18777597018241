export default function () {
  var lotameId = '4810',
    lId = parseInt(lotameId, 10),
    lotameAudiences = [],
    grapeshotChannels = []

  this.setSite = function () {
    var parentDomain = function () {
      try {
        return [top.location.protocol, '//', top.location.hostname].join('')
      } catch (e) {
        return [location.protocol, '//', location.hostname].join('')
      }
    }
    return parentDomain()
  }
  this.parseLotameAudiences = function (data) {
    var json = JSON.stringify(data, undefined, 2),
      obj = (JSON && JSON.parse(json)) || window.jQuery.parseJSON(json)
    var firstParty = obj.Profile.Audiences.Audience || []
    var thirdParty = obj.Profile.Audiences.ThirdPartyAudience || []
    var audiences = firstParty.concat(thirdParty)
    if (audiences) {
      for (var i = 0; i < audiences.length; i++) {
        var audienceId = audiences[i].id
        if (audienceId) {
          lotameAudiences.push(audienceId)
        }
      }
    }
  }
  this.getAudiences = function () {
    var url =
      'https://ad.crwdcntrl.net/5/c=' +
      encodeURIComponent(lId) +
      '/pe=y/callback=itag.parseLotameAudiences'
    var scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.src = url
    document.getElementsByTagName('head')[0].appendChild(scriptTag)
  }
  this.init = function () {
    this.getAudiences(lotameId)
    this.buildGrapeshotUrl(
      (function () {
        return (
          'johnston.grapeshot.co.uk/main/channels-jsonp.cgi?url=' +
          location.href
        )
      })(),
    )
  }
  this.getAudienceIds = function () {
    return lotameAudiences
  }
  this.getGrapeshotChannels = function () {
    return grapeshotChannels
  }
  this.buildGrapeshotUrl = function (endPointUrl) {
    var gsUrl = [
      top.location.protocol,
      '//',
      endPointUrl,
      top.location.href,
    ].join('')
    var gsScript = document.createElement('script')
    gsScript.type = 'text/javascript'
    gsScript.src = gsUrl
    document.getElementsByTagName('head')[0].appendChild(gsScript)
  }
  // Parse grapeshot responses. Callback function needs to be scoped to the window.
  window.parseResponse = function (grapeshotResponse) {
    for (var key in grapeshotResponse) {
      for (let i = 0; i < grapeshotResponse[key].length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            'name',
            grapeshotResponse[key][i],
          )
        ) {
          grapeshotChannels.push(grapeshotResponse[key][i].name)
        }
      }
    }
  }
}
