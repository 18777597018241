import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ComponentThemeProvider } from '~/library/providers/ComponentThemeProvider'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'

const TopicWrapper = styled.div`
  clear: both;
  width: 100%;
  margin-top: ${({ isPremiumArticleLayout }) =>
    !isPremiumArticleLayout && '20px'};

  .article-tag a {
    font-family: ${({ theme }) => theme.fontFamily.openSans};
    display: inline-block;
    font-size: 14px;
    margin: ${({ isPremiumArticleLayout }) =>
      isPremiumArticleLayout ? '5px 10px 5px 0' : '0 8px 8px 0'};
    padding: 6px 12px;
    border: 1px solid #d8d8d8;
    text-decoration: none;
    color: ${({ theme }) => theme.darkContrast};
    text-transform: capitalize;
    border-radius: 25px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    &:hover {
      color: ${({ theme }) => theme.lightContrast};
      background-color: ${({ theme, hierarchy, article }) => {
        if (theme.domain === 'farminglife.com') {
          return getPrimaryColorBasedOffAllSections(theme, article?.sections)
        }
        return getSectionPrimaryColor(theme, hierarchy)
      }};
      border-color: ${({ theme, hierarchy, article }) => {
        if (theme.domain === 'farminglife.com') {
          return getPrimaryColorBasedOffAllSections(theme, article?.sections)
        }
        return getSectionPrimaryColor(theme, hierarchy)
      }};
    }
  }

  .related {
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }
`
const TopicWrapperContrast = styled(TopicWrapper)`
  margin-top: 25px;
  .article-tag a {
    color: ${({ theme }) => theme.lightContrast};
    border: 1px solid ${({ theme }) => theme.lightContrast};
    &:hover {
      background-color: ${({ theme }) => theme.lightContrast};
      color: ${({ theme }) => theme.darkContrast} !important;
      border-color: ${({ theme }) => theme.lightContrast};
    }
  }
`
const Topics = ({
  isPremiumArticleLayout,
  theme,
  topics,
  hierarchy,
  article,
}) => {
  const ThemedWrapper = ComponentThemeProvider(
    theme,
    TopicWrapperContrast,
    TopicWrapper,
  )

  const hierarchyName =
    hierarchy && hierarchy.length > 0 ? hierarchy[0]?.name : undefined
  return (
    <ThemedWrapper
      hierarchy={hierarchyName}
      article={article}
      isPremiumArticleLayout={isPremiumArticleLayout}
    >
      <span className="related">Related topics:</span>
      <span className="article-tag">
        {topics.map(({ name, path }, index) => (
          <a
            key={index}
            className="topic"
            href={path}
            data-position={index}
            data-vars-name={name}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        ))}
      </span>
    </ThemedWrapper>
  )
}

Topics.propTypes = {
  isPremiumArticleLayout: PropTypes.bool,
  theme: PropTypes.string,
  hierarchy: PropTypes.any,
  article: PropTypes.any,
  topics: PropTypes.array,
}

export default Topics
