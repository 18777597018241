/* globals JSGlobals */
export function RefreshImpressionViewable(slot, prebidCallback) {
  this.googletag = window.googletag || {}
  this.slot = slot
  this.interval = 20000
  this.count = 0
  this.refresh = this.refresh.bind(this)
  this.timer = null
  this.timerStart = 0
  this.timerRemaining = 0
  this.requestPrebid = prebidCallback
}

export function isInView(el) {
  var rect = el.getBoundingClientRect()
  var elemTop = rect.top
  var elemBottom = rect.bottom
  return elemTop < window.innerHeight && elemBottom >= 0 // partially visible
}

RefreshImpressionViewable.prototype.init = function () {
  this.timer = setTimeout(this.refresh, this.interval)
  this.timerStart = Date.now()
  this.timerRemaining = this.interval
}

RefreshImpressionViewable.prototype.pause = function () {
  if (!this.timer) {
    return
  }
  window.clearTimeout(this.timer)
  this.timer = null
  this.timerRemaining = this.timerRemaining - (Date.now() - this.timerStart)
}

RefreshImpressionViewable.prototype.unpause = function () {
  if (this.timer) {
    return
  }
  this.timer = window.setTimeout(this.refresh, this.timerRemaining)
  this.timerStart = Date.now()
}

RefreshImpressionViewable.prototype.refresh = function () {
  // Ads will not refresh if you're using devtools/inspector!
  if (!(document.visibilityState === 'visible' && document.hasFocus())) {
    this.timer = setTimeout(this.refresh, this.interval)
    return
  }
  this.count++
  this.slot.setTargeting('rfs', this.count)
  if (this.requestPrebid) {
    this.requestPrebid([[this.slot]])
  } else {
    this.googletag.pubads().refresh([this.slot])
  }
}

export const refreshImpressionViewable = (prebid) => {
  var googletag = window.googletag || {}
  let cached = {}

  let skipSlots = []

  window.addEventListener(
    'message',
    function (e) {
      try {
        const data = JSON.parse(e.data)

        if (data.skipRefreshSlot) {
          skipSlots = [...data.skipRefreshSlot]
        }
      } catch (e) {
        // noop
      }
    },
    false,
  )

  googletag
    .pubads()
    .addEventListener('slotVisibilityChanged', function (event) {
      const id = event.slot.getSlotElementId()
      if (!cached[id]) return
      if (event.inViewPercentage < 30) {
        cached[id].pause()
      }
      if (event.inViewPercentage >= 30) {
        cached[id].unpause()
      }
    })

  googletag.pubads().addEventListener('impressionViewable', function (event) {
    if (!event.isEmpty) {
      const id = event.slot.getSlotElementId()
      const {
        pos: [posAd],
      } = event.slot.getTargetingMap()

      if (skipSlots.includes(posAd)) {
        return
      }

      if (!event.slot.getTargeting('refresh').includes('true')) {
        return
      }

      if (!cached[id]) {
        cached[id] = new RefreshImpressionViewable(event.slot, prebid)
        cached[id].init()
      } else if (cached[id].count > 0) {
        cached[id].init()
      }
    }
  })
}

// @todo move to api if this gets rolled out
const ARBITRAGE_AD_IDS = {
  'sunderlandecho.com': 'content_arb.jp/sunderland_echo',
}

export const getAdSlot = () => {
  if (!JSGlobals.addUnitId)
    throw 'Unable to find ad unit id, please check the ad unit id for this publication.'

  const { pathname } = window.location
  const path = pathname.split('/').filter((item) => item)
  const childId = JSGlobals.publisherChildId
    ? `,${JSGlobals.publisherChildId}`
    : ''

  const galleriesRegex = new RegExp('/galleries(/.*)?$')
  const isArbitrage =
    JSGlobals.pageType === 'photo gallery' ||
    galleriesRegex.test(location.pathname)

  const arbitrageId = ARBITRAGE_AD_IDS[JSGlobals.domain]
  let bottomLevelSection = 'home'

  if (pathname.length > 1 && path.length > 0) {
    if (JSGlobals.pageType !== 'category') path.pop()
    bottomLevelSection = path.join('/')
  }

  if (isArbitrage && arbitrageId) {
    return `/${JSGlobals.networkId}${childId}/${arbitrageId}/${bottomLevelSection}`
  }

  return `/${JSGlobals.networkId}${childId}/${JSGlobals.addUnitId}/${bottomLevelSection}`
}

/**
 * The function generates a random identifier, sets it as a cookie and sets it as the publisher
 * provided ID for Google ads.
 */
const setIdentifier = () => {
  var googletag = window.googletag || {}
  let identifier = ''
  const min = 32
  const max = 150
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (let i = 0; i < randomNum; i++) {
    let numOrLetter = Math.floor(Math.random() * 2)
    numOrLetter === 1
      ? (identifier += alphabet.charAt(Math.floor(Math.random() * 25)))
      : (identifier += Math.floor(Math.random() * 9))
  }
  let expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 400)

  document.cookie =
    'NationalWorld_PPID=' +
    identifier +
    '; expires=' +
    expirationDate.toUTCString()
  console.log('PPID cookie set')
  googletag.pubads().setPublisherProvidedId(identifier)
  // Enable single request and services
  googletag.pubads().enableSingleRequest()
  googletag.enableServices()
  console.log('new ppid set', identifier)
}

/**
 * The function checks if the user has a PPID (Publisher Provided Identifier) cookie and
 * sets it as the publisher provided ID for Google ads if it meets certain length requirements. If the
 * user does not have a PPID cookie or the cookie does not meet the length requirements, it generates
 * a new identifier and sets it as the PPID cookie and the publisher provided ID for Google ads.
 */
export const ppid = () => {
  // @todo refactor to smaller functions
  var googletag = window.googletag || {}
  let ppidIdentifier = ''
  let cookieStr = document.cookie.split(';')
  let hasPpid = false
  for (let i = 0; i < cookieStr.length; i++) {
    if (cookieStr[i].includes('NationalWorld_PPID')) {
      console.log('User has PPID')
      hasPpid = true
      ppidIdentifier = cookieStr[i].split('=')[1]
    }
  }
  if (hasPpid) {
    if (ppidIdentifier.length >= 32 && ppidIdentifier.length <= 150) {
      googletag.pubads().setPublisherProvidedId(ppidIdentifier)
      // Enable single request and services
      googletag.pubads().enableSingleRequest()
      googletag.enableServices()
      console.log('google set ID:', ppidIdentifier)
    } else {
      setIdentifier()
    }
  }
  if (!hasPpid) {
    setIdentifier()
  }
}

/**
 * Build GPT script tag
 * avoids amp issues with script tag src
 */
export const gptScriptTag = `
<script type="text/javascript" data-script-priority="highest">
  (function() {
    const gptScript = document.createElement('script')
    gptScript.type = 'text/javascript'
    gptScript.async = true
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(gptScript, s)
  })();
</script>`
