let edktAudiences

// Here we fetch audiences which the user has been placed in.
try {
  edktAudiences = JSON.parse(
    localStorage.getItem('edkt_matched_audience_ids') || '[]',
  )
    .slice(0, 100)
    .map(String)
} catch (e) {
  edktAudiences = []
}

export default edktAudiences
