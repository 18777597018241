/*globals JSGlobals*/
import {
  getAuthorsName,
  getAuthorSlug,
  getPageCategory,
} from '~/services/authors'
import { getOutbrainElementIds } from './get-outbrain-element-ids'
import cohortSites from '~/config/cohortSites'

const dataLayer = () => {
  window.dataLayer = window.dataLayer || []
  let data = {
    event: 'pageDataLoaded',
    trackingId: JSGlobals.ga,
    pageType: JSGlobals.pageType,
    headline: undefined,
    articleId: undefined,
    headlineWordCount: undefined,
    obUnits: getOutbrainElementIds(),
    publishedDate: undefined,
    sponsorName: undefined,
    siteKey: JSGlobals.siteKey,
  }

  if (JSGlobals.article) {
    data = Object.assign({}, data, {
      articleId: JSGlobals.pageId,
      articleType: JSGlobals.articleType,
      centralId: JSGlobals.article.remoteId ?? JSGlobals.pageId,
      articleWordCount: JSGlobals.article.wordCount,
      imageCount: JSGlobals.article.imageCount,
      headline: JSGlobals.article.title,
      authorName: getAuthorsName(JSGlobals.article.authors),
      authorSlug: getAuthorSlug(JSGlobals.article.authors),
      contributor: JSGlobals.article.isUserGenerated,
      headlineWordCount: JSGlobals.article.title.split(' ').length,
      publishedDate: JSGlobals.article.publish,
      sponsorName: JSGlobals.article.sponsorName,
      pageCategories: getPageCategory(JSGlobals.article.hierarchy),
      siteSection: JSGlobals.article.sectionCategory
        .map(({ siteSection }) => siteSection)
        .join('|'),
      additionalKeywords:
        (JSGlobals.article.topics &&
          JSGlobals.article.topics.map(({ name }) => name.toLowerCase())) ||
        null,
      dmPlayerPresent: JSGlobals.article.containDailyMotion ? 'TRUE' : 'FALSE',
      premiumContent: JSGlobals.premium,
      sensitiveContent: JSGlobals.sensitive,
      affiliateArticle: JSGlobals.article.isAffiliated,
      infiniteScroll: JSGlobals.isInfiniteScrollArticle ? 1 : 0,
    })
  }

  window.dataLayer.push(data)
}

export const initGTMScript = () => {
  const untrackedPaths = ['/dev/header', '/dev/footer']
  if (untrackedPaths.includes(location.pathname)) {
    return
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'trackingIdAvailable',
    trackingId: JSGlobals.ga,
  })

  try {
    dataLayer()
  } catch (ex) {
    console.error(ex)
  }

  try {
    if (
      JSGlobals.mode === 'production' &&
      cohortSites.default.includes(JSGlobals.domain)
    ) {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' +
          i +
          dl +
          '&gtm_auth=six2T6CxkN1YYbqLKUV2RQ&gtm_preview=env-7&gtm_cookies_win=x'
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-NDG2GKQ')
    } else if (JSGlobals.mode === 'production') {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-NDG2GKQ')
    } else {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' +
          i +
          dl +
          '&gtm_auth=38T7JxqcEhq_kBdjkW20Bg&gtm_preview=env-5&gtm_cookies_win=x'
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-NDG2GKQ')
    }
  } catch (ex) {
    console.error(ex)
  }
}
