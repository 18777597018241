export const resizeStickySidebar = () => {
  const hasOutbrain = !!document.querySelector('.ob-smartfeed-wrapper')
  const firstSidebarGroup = document.querySelector('#first-sticky-group')
  const secondSidebarGroup = document.querySelector('#second-sticky-group')
  const thirdSidebarGroup = document.querySelector('#third-sticky-group')
  const finalSidebarGroup = document.querySelector('#final-sticky-group')
  const articleContent = document.querySelector('.article-content')

  const { height: articleHeight } =
    articleContent?.getBoundingClientRect() ?? {}

  const stickyHeightFirstGroup = () =>
    articleHeight
      ? Math.max(Math.round(articleHeight) * 0.5, 2000) + 'px'
      : 'auto'

  const stickyHeightOtherGroups = () =>
    articleHeight
      ? Math.max(Math.round(articleHeight) * 0.3, 1000) + 'px'
      : 'auto'

  // Ad0 + SponsoredTeaser1
  firstSidebarGroup?.setAttribute(
    'style',
    `height: ${hasOutbrain ? stickyHeightFirstGroup() : 'auto'}`,
  )

  // NewsLetterSignup2 + Ad3
  secondSidebarGroup?.setAttribute(
    'style',
    `height: ${hasOutbrain ? stickyHeightOtherGroups() : 'auto'}`,
  )

  // piano-article-sidebar + Ad4
  thirdSidebarGroup?.setAttribute(
    'style',
    `height: ${hasOutbrain ? stickyHeightOtherGroups() : 'auto'}`,
  )

  // Ad5 …
  finalSidebarGroup?.setAttribute('style', 'position: sticky; top: 190px;')
}

const sideBarInterval = setInterval(resizeStickySidebar, 2000) // resize every 2 seconds until page layout has settled.
setTimeout(() => clearInterval(sideBarInterval), 16000)
