import { Domain } from '~/types'

const whitelistedDomains = ['lancasterguardian.co.uk']
const blacklistedSections = ['Crime', 'Troubles', 'Must Read']
const blacklistedArticleTypes = ['affiliate article', 'sponsored article']

const isWhitelistedDomain = (domain: Domain) =>
  whitelistedDomains.includes(domain)
const isBlacklistedArticleTypes = (pageType: string) =>
  blacklistedArticleTypes.includes(pageType)
const isBlacklistedSection = (sections: { name: string }[] | undefined) =>
  sections?.some((section) => blacklistedSections.includes(section.name))

export const shouldGetContextualVideo = (
  domain: Domain,
  articleType: string,
  sections: { name: string }[] | undefined,
  isAffiliated: boolean,
  isSensitive: boolean,
) => {
  const isContextualVideoBlacklisted =
    !isWhitelistedDomain(domain) ||
    isBlacklistedArticleTypes(articleType) ||
    isBlacklistedSection(sections) ||
    isAffiliated ||
    isSensitive

  if (isContextualVideoBlacklisted) {
    return false
  }

  return true
}
