import last from 'ramda/src/last'
import React from 'react'
import styled from 'styled-components'
import { customColorLogicSites } from '~/config/JPIMConfig'
import {
  getPrimaryColorBasedOffAllSections,
  getSectionPrimaryColor,
} from '~/config/theme/JPIMStyles'
import { ArticleData, FC } from '~/types'

type StyledBreadcrumbProps = {
  hierarchy?: Array<{ path: string; name: string }>
  hierarchyName: string | undefined
  article?: ArticleData
  isLive?: boolean
}

const StyledBreadcrumb = styled.nav<StyledBreadcrumbProps>`
  color: ${({ theme, hierarchyName }) =>
    customColorLogicSites.includes(theme.domain)
      ? getSectionPrimaryColor(theme, hierarchyName)
      : theme.darkestContrast};
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  text-align: left;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 0;

  a {
    text-decoration: none;
    color: ${({ theme, hierarchyName, article }) => {
      if (theme.domain === 'farminglife.com') {
        return getPrimaryColorBasedOffAllSections(theme, article?.sections)
      }
      return getSectionPrimaryColor(theme, hierarchyName)
    }};
    &:after {
      content: '>';
      margin-left: 10px;
      margin-right: 10px;
    }

    &:last-child:after {
      display: none;
    }
  }
  &.live-breadcrumb,
  &.live-breadcrumb a {
    color: ${({ theme }) => theme.redContrast};
  }
`

export type BreadcrumbProps = {
  className?: string
  hierarchy?: Array<{ path: string; name: string }>
  article?: ArticleData
}

const Breadcrumb = styled<FC<BreadcrumbProps>>(
  ({ className, hierarchy, article }) => {
    if (!hierarchy) return null

    const hierarchyName = hierarchy[0]?.name
    const category = last(hierarchy)
    const isLive = article?.liveblog && article?.liveblog?.data?.status === 1
    return (
      <StyledBreadcrumb
        className={[className, isLive ? 'live-breadcrumb' : ''].join(' ')}
        hierarchyName={hierarchyName}
        article={article}
        isLive={isLive}
      >
        {category && <a href={category.path}>{category.name}</a>}
      </StyledBreadcrumb>
    )
  },
)``

export const BreadcrumbDark = styled(Breadcrumb)`
  color: ${({ theme }) => theme.lightContrast};
  margin-top: 24px;
  margin-bottom: 16px;

  a {
    color: ${({ theme }) => theme.lightContrast};
  }

  .premium-tag {
    font-size: ${({ theme }) =>
      /^(The Scotsman|Yorkshire Post)$/.test(theme.name) ? '10px' : '12px'};
    line-height: ${({ theme }) =>
      /^(The Scotsman|Yorkshire Post)$/.test(theme.name) ? '13px' : '16px'};
    font-family: ${({ theme }) =>
      /^(The Scotsman|Yorkshire Post)$/.test(theme.name)
        ? `${theme.fontFamily.titleFont}`
        : `${theme.fontFamily.primaryFont}`};
    color: ${({ theme }) =>
      theme.name === 'Yorkshire Post'
        ? `${theme.darkestContrast}`
        : `${theme.lightContrast}`};
    background-color: ${({ theme }) =>
      theme.name === 'Yorkshire Post'
        ? `${theme.secondaryColor}`
        : `${theme.primaryColor}`};
  }
`

export default Breadcrumb
