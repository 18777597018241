import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { device } from '~/config/theme/JPIMStyles'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff0000;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 18px;
  padding: 8px 16px;
  margin-top: 12px;
  margin-bottom: 20px;

  @media ${device.maxTablet} {
    margin-left: 12px;
    margin-right: 12px;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
`
const LineBreak = styled.br`
  @media ${device.maxMobileM} {
    display: none;
  }
`

const ShotsTvBanner = () => {
  return (
    <Wrapper>
      <div>
        Watch more of our videos on ShotsTV.com&nbsp;
        <LineBreak />
        and on Freeview 262 or Freely 565
      </div>
      <Button
        href="https://www.shotstv.com"
        target="_blank"
        rel="noopener noreferrer"
        rounded
        textL
        bold
        backgroundColour="lightContrast"
        textColour="darkContrast"
        id="shots-tv-banner"
      >
        Visit Shots! now
      </Button>
    </Wrapper>
  )
}

export default ShotsTvBanner
