import { getValueOrDefault } from '~/library/Article/utils/article-utils'
import { DEFAULT_POSITIONS } from '~/constants/ads'
import { getMidOutbrainDefaultPosition } from './getMidOutbrainDefaultPosition'
import { AdConfigData } from '~/types'

/**
 * Gets ad config for articles
 * @param adConfig
 * @param domain
 * @param articleLength
 * @returns {AdManagerConfig}
 */
export const getAdManagerArticleConfig = (
  adConfig: AdConfigData,
  domain: string,
  articleLength: number = 0,
) => {
  const midOutbrainDefaultPosition = getMidOutbrainDefaultPosition(
    domain,
    articleLength,
  )

  return {
    ...adConfig,
    firstTeadsPosition: getValueOrDefault(
      DEFAULT_POSITIONS.FIRST_TEADS,
      adConfig.desktop?.teads_override_adslot_1,
    ),
    secondTeadsPosition: getValueOrDefault(
      DEFAULT_POSITIONS.SECOND_TEADS,
      adConfig.desktop?.teads_override_adslot_2,
    ),
    midOutbrainPosition: getValueOrDefault(
      midOutbrainDefaultPosition,
      adConfig.desktop?.mid_ob_override_adslot,
    ),
  }
}
