/*globals JSGlobals*/
import { jsLoader } from './fileLoaders'

export const DATA_FLUID_ZONE_ID = '18412'
export const DATA_FLUID_ID = 'e6404a5432b1988ea2e71ec092e8608a'

export const shouldGetSkyPlayer = () => {
  const { article, domain } = JSGlobals
  const mediaId = article.DMVideoId
  const isSportsArticle = window.location.pathname.split('/')[1] === 'sport'
  const isCommercialArticle = article?.isCommercial
  const skyPlayerBlacklistedSites = ['totallysnookered.com']
  const isSkyPlayerBlacklistedSite = skyPlayerBlacklistedSites.includes(domain)

  return (
    isSportsArticle &&
    !mediaId && // no video
    !isCommercialArticle && // do not show on commercial articles
    !isSkyPlayerBlacklistedSite // do not show on blacklisted sites
  )
}

export const getSkyPlayer = () => {
  jsLoader(['//fluid.4strokemedia.com/www/fluid/player.php'], 'skyplayer')
  // Replace Dailymotion Player with SkyPlayer
  const dailymotionDiv = document.getElementById('hero-slot')
  const skyPlayerDiv = document.createElement('div')
  skyPlayerDiv.id = 'sky-player'
  const insTag = document.createElement('ins')
  insTag.setAttribute('data-fluid-zoneid', DATA_FLUID_ZONE_ID)
  insTag.setAttribute('data-fluid-id', DATA_FLUID_ID)
  skyPlayerDiv.appendChild(insTag)
  dailymotionDiv.replaceWith(skyPlayerDiv)
  return
}
