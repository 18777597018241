/*globals googletag*/

import apstag from './apstag'
import once from 'ramda/src/once'
import { demandManager } from './prebidDemandManager'

const executeParallelAuctionProvider = (params) => {
  return new Promise((resolve) => {
    const FAILSAFE_TIMEOUT = 2000
    const sendAdserverRequest = once(() => {
      googletag.cmd.push(function () {
        const [slots] = params
        googletag.pubads().refresh(slots)
        resolve(demandManager)
      })
    })
    Promise.all([demandManager(params, true), apstag(params)])
      .then(sendAdserverRequest)
      .catch((ex) => {
        sendAdserverRequest()
        console.error(ex)
      })

    window.setTimeout(function () {
      sendAdserverRequest()
    }, FAILSAFE_TIMEOUT)
  })
}

export default executeParallelAuctionProvider
