import React, { createContext } from 'react'
import { ArticleData, ArticleExtra, Configuration, Domain, FC } from '~/types'
import { pageTitle } from '~/util/pageTitle'

import isPremium from './utils/isPremium'
import addDropCap from './utils/add-drop-cap'
import isExclusive from './utils/isExclusive'
import hierarchyName from './utils/hierarchyName'
import isAxateEnabled from './utils/isAxateEnabled'
import isReviewPresent from './utils/isReviewPresent'
import isInfiniteScroll from './utils/isInfiniteScroll'
import products from './utils/products'

export const ArticleContentContext = createContext(
  {} as {
    article: ArticleData
    title: string
    isPremium: boolean
    isExclusive: boolean
    hierarchyName: string
    isAxateEnabled: boolean
    isReviewPresent: boolean
    isInfiniteScroll: boolean
    products: Array<any>
    addDropCap: boolean
  },
)

type ArticleContentProviderProps = {
  article: ArticleData
  domain: Domain
  configuration: Configuration
  url: string
  children: React.ReactNode
}

export const ArticleContentProvider: FC<ArticleContentProviderProps> = ({
  article,
  domain,
  configuration,
  url,
  children,
}) => {
  let value: any = {
    article,
    title: pageTitle({ article }, configuration.name),
    isPremium: isPremium(article.premium, domain),
    isExclusive: isExclusive(article.extra),
    hierarchyName: hierarchyName(article.hierarchy?.[0]),
    isAxateEnabled: isAxateEnabled(domain),
    isReviewPresent: isReviewPresent(article.articleTypes, article.extra),
    isInfiniteScroll: isInfiniteScroll(url),
    products: products({
      articleProducts: article.products,
      articleExtra: article.extra as ArticleExtra,
    }),
  }

  value = {
    ...value,
    addDropCap: addDropCap(article.articleTypes, value.isPremium, domain),
  }

  return (
    <ArticleContentContext.Provider value={value}>
      {children}
    </ArticleContentContext.Provider>
  )
}
