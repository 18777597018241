import React from 'react'
import { getAdConfigSetting } from '~/providers/AdManager/utils/getAdConfigSetting'
import { getAdSlotIncrementAppendix } from '~/providers/AdManager/utils/getAdSlotIncrement'
import {
  ADCONFIG_DESKTOP,
  ADCONFIG_DESKTOP_LITE,
  ADCONFIG_MOBILE,
  ADCONFIG_MOBILE_LITE,
  ADSETTING_PARAGRAPH_FREQ,
  ADSETTING_PARAGRAPH_SIZE,
  ADSETTING_MPU1_POSITION,
} from '~/constants/ads'
import { MPU, MobileMpu } from '~/library/Article/Ads/MPU'
import { AdsContainer } from '~/library/Article/Ads/MarkupAds'
import styled from 'styled-components'

import { device } from '~/config/theme/JPIMStyles'
import { premiumArticleLayoutSites } from '~/config/JPIMConfig'

const AdContainer = styled.div`
  width: 100%;
  height: 314px;
  overflow: hidden;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.adContainerGrey};
  position: relative;
  border-top: 2px solid ${({ theme }) => theme.greyContrast};
  border-bottom: 2px solid ${({ theme }) => theme.greyContrast};

  @media ${device.tablet} {
    display: none;
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    content: 'Ad loading…';
    z-index: 1;
  }
`

const DesktopAdContainer = styled(AdContainer)`
  @media ${device.maxTablet} {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`

const LiteArticleAdSlot = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  justify-items: center;
  align-items: center;

  #google_pubconsole_overlay_liteArticleAdSlot1 {
    top: unset !important;
  }
`

const ADGROUPS = [ADCONFIG_DESKTOP, ADCONFIG_MOBILE]

const ADGROUPS_LITE = [ADCONFIG_DESKTOP_LITE, ADCONFIG_MOBILE_LITE]

/** Function to initialize MPU counts for each ad group */
export const buildMPUCounts = () => {
  return ADGROUPS.map(() => 0)
}

/** Function to get the appropriate MPU component based on the group and position */
const getMPU = (group, position, props) => {
  const { article, configuration } = props
  const isPremiumArticleLayout =
    article.premium && premiumArticleLayoutSites.includes(configuration.domain)
  const { isAdFree } = configuration
  if (isAdFree) return null
  const piano = configuration.piano
  const showNoAdsLink =
    piano && piano.subscription && !piano.deprecateSubscription

  const idAppendix = `${position + 1}${getAdSlotIncrementAppendix()}`

  switch (group) {
    case ADCONFIG_MOBILE:
      return (
        <AdContainer
          key={`articleMPU${idAppendix}-mobile`}
          data-testid="article-content mobile-ads-container"
        >
          <AdsContainer showNoAdsLink={showNoAdsLink}>
            <MobileMpu id={`articleMPU${idAppendix}-mobile`} />
          </AdsContainer>
        </AdContainer>
      )
    case ADCONFIG_MOBILE_LITE:
      return (
        <AdContainer
          key={`liteArticleMPU${idAppendix}-mobile`}
          data-testid="article-content mobile-lite-ads-container"
        >
          <AdsContainer showNoAdsLink={false}>
            <MobileMpu id={`liteArticleMPU${idAppendix}-mobile`} />
          </AdsContainer>
        </AdContainer>
      )
    case ADCONFIG_DESKTOP: {
      return (
        <DesktopAdContainer
          key={`articleMPU${idAppendix}`}
          data-testid="article-content desktop-ads-container"
        >
          <AdsContainer showNoAdsLink={showNoAdsLink}>
            <MPU id={`articleMPU${idAppendix}`} />
          </AdsContainer>
        </DesktopAdContainer>
      )
    }
    case ADCONFIG_DESKTOP_LITE:
      return (
        <DesktopAdContainer
          key={`liteArticleAdSlot${idAppendix}`}
          data-testid="article-content desktop-lite-ads-container"
        >
          <AdsContainer showNoAdsLink={false}>
            {!isPremiumArticleLayout ? (
              <MPU id={`liteArticleMPU${idAppendix}`} />
            ) : (
              <LiteArticleAdSlot id={`liteArticleAdSlot${idAppendix}`} />
            )}
          </AdsContainer>
        </DesktopAdContainer>
      )
    default:
      return null
  }
}

/** Function to get the list of MPU components based on the ad config, index, and counts */
export const getMPUs = (adConfig, index, counts, lite, props) => {
  const mpus = []
  if (index === 0) {
    return mpus
  }

  const groups = lite ? ADGROUPS_LITE : ADGROUPS

  for (let i = 0; i < groups.length; i += 1) {
    const freq = getAdConfigSetting(
      adConfig,
      ADSETTING_PARAGRAPH_FREQ,
      groups[i],
    )
    const size = getAdConfigSetting(
      adConfig,
      ADSETTING_PARAGRAPH_SIZE,
      groups[i],
    )
    const mpu1pos = getAdConfigSetting(
      adConfig,
      ADSETTING_MPU1_POSITION,
      groups[i],
    )

    const shouldShowMPU = (() => {
      if (mpu1pos) {
        if (mpu1pos === index) {
          return true
        }

        if ((index - mpu1pos) % freq === 0) {
          return true
        }

        return false
      }

      if (index % freq === 0) {
        return true
      }

      return false
    })()

    if (!freq) {
      continue
    }
    if (!Number.isInteger(size) || counts[i] < size) {
      if (shouldShowMPU) {
        const mpu = getMPU(groups[i], counts[i], props)
        if (mpu) {
          mpus.push(mpu)
          counts[i] += 1
        }
      }
    }
  }
  return mpus
}
