export const initLiveRamp = () => {
  // Capture sign up newsletter
  try {
    window.ats.start({
      placementID: 1426,
      storageType: 'cookie',
      detectionType: 'scrape',
      cssSelectors: ['input[type=text]', 'input[type=email]'],
      logging: 'debug',
      pixelID: 709073,
    })
  } catch (ex) {
    // console.error(ex)
  }
}

const sendToLiveRamp = ({ email }) => {
  try {
    window.ats.start({
      placementID: 1426,
      storageType: 'cookie',
      email: email,
      logging: 'debug',
    })
  } catch (ex) {
    // console.error(ex)
  }
}

export default sendToLiveRamp
