export const theScotsman = [
  {
    image: '/assets/static/Scotsman-Today.png',
    section: 'The Scotsman Today',
    text: 'Regular news stories and round-ups from around Scotland',
    opt_in: 'scot_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Scotsman-Breaking-News.png',
    section: 'Breaking News',
    text: "Read about Scotland's biggest stories as they happen. Breaking news alerts sent directly to your inbox",
    opt_in: 'scot_breakingnews_opt_in',
    frequency: '',
  },
  {
    image: '/assets/static/Scotsman-Editor-Letter.png',
    section: 'Letter from the Editor',
    text: 'Get a selection of our best stories and the perspective of The Scotsman Editor, Neil McIntosh',
    opt_in: 'scot_lfte_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Scotsman-Insider.png',
    section: 'The Scotsman Insider',
    text: 'The Scotsman Insider: Get emails from our editor on the biggest issues affecting Scotland, as well as the latest competitions, promotions, giveaways, surveys, event invitations and news from The Scotsman newsroom',
    opt_in: 'scot_marketing_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Scotsman-Politics.png',
    section: 'Politics',
    text: 'The latest Scottish political news and analysis from our specialist writers direct to your inbox',
    opt_in: 'scot_politics_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Scotsman-Money-Newsletter.png',
    section: 'Scotsman Money',
    text: "The Scotsman's personal finance strand covering all you need to know to help manage your money, including savings, investments, mortgages, pensions, tax planning, with expert opinion and much more",
    opt_in: 'scot_money_opt_in',
    frequency: 'Bi-weekly',
  },
  {
    image: '/assets/static/Scotsman-Rural-News.png',
    section: 'Scotsman Rural',
    text: 'Scotsman Rural News - A weekly of the Hay’s Way tour of Scotland emailed direct to you weekly',
    opt_in: 'scot_ruralaffairs_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Scotsman-Opinion.png',
    section: 'Opinion',
    text: 'Expert analysis and agenda-setting opinion from our team of columnists',
    opt_in: 'scot_opinion_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Scotsman-Heritage.png',
    section: 'History and Heritage',
    text: 'Features on Scottish history, nostalgia and archive picture galleries',
    opt_in: 'scot_history_opt_in',
    frequency: 'Weekly',
  },
  {
    image: '/assets/static/Scotsman-The-World-Explained.png',
    section: 'The World Explained',
    text: 'A daily digest of world news from The Scotsman, what’s happening and why it matters to you',
    opt_in: 'scot_world_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Scotsman-Art-Culture.png',
    section: 'Arts and Culture',
    text: 'All the latest news and reviews from our specialist arts writers',
    opt_in: 'scot_arts_opt_in',
    frequency: '',
  },
  {
    image: '/assets/static/Scotsman-Scottish-Football.png',
    section: 'Scottish Football',
    text: 'Providing access to interviews, transfer news and expert analysis and special reports covering events such as the Euros and more',
    opt_in: 'scot_football_opt_in',
    frequency: 'Daily',
  },
  {
    image: '/assets/static/Scotsman-Golf.png',
    section: 'Golf',
    text: 'Martin Dempster talks you through what’s going on in the world of golf',
    opt_in: 'scot_sport_golf_opt_in',
    frequency: '',
  },
  {
    image: '/assets/static/Scotsman-Rugby.png',
    section: 'Rugby',
    text: 'Graham Bean talks you through what’s going on in the world of rugby',
    opt_in: 'scot_sport_rugby_opt_in',
    frequency: '',
  },
  {
    image: '/assets/static/Scotsman-Transport.png',
    section: 'Transport',
    text: 'The latest news and analysis about Scottish transport from our specialist correspondent, Alastair Dalton',
    opt_in: 'scot_transport_opt_in',
    frequency: '',
  },
  {
    image: '/assets/static/Scotsman-scran.png',
    section: 'Scran',
    text: 'The Scotsman’s food and drink newsletter from Rosalind Erskine, championing the best Scotland has to offer',
    opt_in: 'scot_scran_opt_in',
    frequency: 'Weekly',
  },
]
