export const isCompWithLead = (
  includeStandfirst: boolean | null,
  type: string | undefined,
  isFeaturedx4: boolean | undefined,
  path: string,
): boolean => {
  // If the type is one of the following and the path is not empty, return true
  // includeStandfirst has to set true to display lead on ArticleX4, ArticleX3
  // Featuredx4Articles is a variation of ArticleX5, and isFeaturedx4 is set to true to display lead on this component
  return !!(
    (type === 'HeroArticle' ||
      type === 'MultiArticleX3PlusNumberedListX5' ||
      type === 'MultiHeroPlus3ArticlesPlusSidebar' ||
      (type === 'ArticleX4' && includeStandfirst) ||
      (type === 'ArticleX3' && includeStandfirst) ||
      isFeaturedx4) &&
    path
  )
}
