import striptags from 'striptags'

/** use to return an override value if valid, otherwise return default value */
export const getValueOrDefault = (defaultValue, overrideValue) =>
  typeof overrideValue === 'number' ? overrideValue : defaultValue

/** use to check if paragraph is equal or greater than 60 characters */
export const isParagraphLongEnough = (markup) =>
  markup.replace(/(<([^>]+)>)/gi, '').length >= 60

/** Function to check that all commerical ad elements are after paragraph markup items, and rearrange if not */
export const ensureCommercialElementsAreAfterParagraphs = (content) => {
  const isCommercialAdElement = (key) => {
    const commercialKeywords = ['outbrain', 'adslottead', 'components']
    const lowerCaseKey = key.toLowerCase()
    return commercialKeywords.some((keyword) => lowerCaseKey.includes(keyword))
  }

  let contentArray = []
  // Initialise the index of the last paragraph markup item found in the content array
  let lastParagraphMarkupItem = -1

  content.forEach((element, index) => {
    // Check if the element is a paragraph markup item
    if (
      element.key.startsWith('markup') &&
      element.props?.data?.markup?.includes('<p>')
    ) {
      // Update the index of the last paragraph markup item found
      lastParagraphMarkupItem = index
      contentArray.push(element)
    }
    // If the content item contains 'outbrain' or 'adSlotTead' or 'components' (MPU), treat it as a commercial ad element - also works for lite users
    else if (isCommercialAdElement(element.key)) {
      // If a paragraph markup item has been found previously
      if (lastParagraphMarkupItem !== -1) {
        // Insert the commercial ad element after the last paragraph markup item in the new array
        contentArray.splice(lastParagraphMarkupItem + 1, 0, element)
      }
      // If no paragraph markup item has been found yet
      else {
        contentArray.push(element)
      }
    } else {
      contentArray.push(element)
    }
  })

  return contentArray
}

/** use to strip elements that Outbrain doesn't like from the end of the content array */
export const stripNonOutbrainFriendlyElements = (content) => {
  const allowedComponents = [
    'markup',
    'markup.*',
    'live-blog',
    'LiveBlog',
    'listbulleted',
    'listnumbered',
    'H2',
    'products',
    'product-verdict',
    'newsletter-signup',
  ]

  const _isAllowed = (componentKey) =>
    allowedComponents.some((type) => {
      const expression = new RegExp(type)
      return expression.test(componentKey)
    })

  const _blogPostBodyCharacterCount = (postJson) => {
    postJson = postJson ?? []

    return postJson.reduce((bodyCharacterCount, bodyItem) => {
      const bodyItemText = striptags(bodyItem?.data?.markup ?? '')
      return bodyCharacterCount + bodyItemText.length
    }, 0)
  }

  const _blogPostCharacterCount = ({ data, postJson } = {}) => {
    const postTitle = data?.title ?? ''

    return postTitle.length + _blogPostBodyCharacterCount(postJson)
  }

  const _liveBlogCharacterCount = ({ type, data = {}, posts = [] } = {}) => {
    if (type !== 'liveblog') return 0

    const liveBlogName = data.name ?? ''
    let characterCount = liveBlogName.length

    posts.forEach((post) => {
      characterCount += _blogPostCharacterCount(post)
    })

    return characterCount
  }

  let characterTotal = 0

  return content
    .reverse()
    .filter((item) => !!item?.key)
    .filter(({ key, props: { data } }) => {
      if (characterTotal >= 200) {
        return true
      }

      if (!_isAllowed(key)) {
        return false
      }

      const { markup = '' } = data ?? {}

      characterTotal += striptags(markup).length
      characterTotal += _liveBlogCharacterCount(data)

      return true
    })
    .reverse()
}

/** use to check if the article contains products and/or affiliate links in the content */
const affiliatePatterns = [
  /https?:\/\/go\.skimresources\.com\/?/,
  /https?:\/\/(www\.)?amazon\.?/,
  /https?:\/\/(www\.)?amzn\.?/,
]

/** Checks if an article contains affiliate links. */
export const containsAffiliateLinks = (article) => {
  return (
    article.products?.length > 0 ||
    article.body?.some(
      (item) =>
        ['listnumbered', 'listbulleted', 'markup'].includes(item.type) &&
        affiliatePatterns.some((pattern) => pattern.test(item.data?.markup)),
    )
  )
}
