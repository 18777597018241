import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

const ReCaptchaAmp = ({ siteKey }) => {
  if (!siteKey) return null

  return (
    <>
      <Helmet>
        <script
          async=""
          custom-element="amp-recaptcha-input"
          src="https://cdn.ampproject.org/v0/amp-recaptcha-input-0.1.js"
        ></script>
      </Helmet>
      <amp-recaptcha-input
        layout="nodisplay"
        name="recaptcha_token"
        data-sitekey={siteKey}
        data-action="recaptcha_example"
        data-testid="newsletter-recaptcha"
      ></amp-recaptcha-input>
      <div
        className="newsletter-message-footer"
        data-testid="newsletter-msg-footer"
      >
        This site is protected by reCAPTCHA and the Google{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>{' '}
        and{' '}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        apply.
      </div>
    </>
  )
}

ReCaptchaAmp.propTypes = {
  siteKey: PropTypes.string,
}

export default ReCaptchaAmp
